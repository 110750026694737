import React, { useState } from 'react'
import Monitor from '../../components/CardMonitor'
import { ContainerMonitor } from './styles'
import Filter from '../../components/statistics/filter'

import FilterMobile from '../../components/statistics/filterMobile'

import { useMediaQuery } from '@mui/material';




const MonitorPage: React.FC = () => {
  const isDesktop = useMediaQuery('(min-width:720px)');

  const [provider, setProvider] = useState('');
  const [tipoMesa, setTipoMesa] = useState('');
  const [grupo, setGrupo] = useState('');
  const [dataInicial, setDataInicial] = useState('');
  const [dataFinal, setDataFinal] = useState('');


  return (
    <>
      <ContainerMonitor>
        <Monitor/>
      </ContainerMonitor>
    </>
  )
}

export default MonitorPage
