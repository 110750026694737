import React, { useEffect } from 'react'
import { Background, ContainerBody } from './styles'
import { Outlet } from 'react-router-dom'
import TopBar from '../../components/topbar'
import SideBar from '../../components/sidebar'




const Layoult: React.FC = () => {
  

  return (
    <>
      <Background>
        <SideBar />
        <ContainerBody>
          <div>
            <TopBar />
          </div>
          <Outlet />
        </ContainerBody>
      </Background>
    </>
  )
}

export default Layoult
