import styled from "styled-components"

export const ContainerLogin = styled.div`
  display: flex;
  height: 100%;
`

export const ContentBackground = styled.div`
width: 2000px;
height: 2000px;
// border: 1px solid;
// background: radial-gradient(119.77% 119.77% at 50% -17.6%, #172955 11.98%, rgba(10, 20, 43, 0) 39.58%);
background-color: black
`
export const PaiLogin = styled.div`
//   display: flex;
//   align-items: center;
//   justify-content: center;

height: 200px;
display: flex;
align-items: center;
justify-content: center;
  
`
export const Cardlogin = styled.div`

width: 10em;

    height: 200px;
    width: 200px;
  background: white;
  opacity: 0.5;
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.35);
  border-radius: 12px;
  
  
`