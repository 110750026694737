import CrudTopbar from "../../components/Crud/crud-topbar";
import CrudTabela from "../../components/Crud/crud-tabela";
import Modal from "../../components/Modal";
import { Button } from "@mui/material";
import { useEffect } from "react";
import { useAuth } from "../../context/auth.context";
import { useCriarCliente } from "../../context/criarCliente.context";

const Replicador = () => {
  const { HandleAdicionarModal, adicionarModal, AddCliente, EditCliente, clientes, EditClienteData, setClienteSendoEditado, clienteSendoEditado, parametrosDelete, setParametrosDelete, GetClientes, setClientes } = useCriarCliente();

  useEffect(()=>{
    const verifAuth = () => {
      const verifToken = localStorage.getItem('@Token:SmarttipsAdmin')
      if (verifToken == null || verifToken == '') {
        window.location.href = './login'
      }
    }
    verifAuth()
  }, [])

  useEffect(() => {
	  GetClientes()
	}, [])

  return (
    <>
      <CrudTabela
        nome="Replicador de Jogos"
        columns={[...clientes.columns, {
          label: "Ações",
          options: {
            customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
              return (
                <>
                  <Button onClick={() => {
                    console.log(value, tableMeta)
                    EditClienteData(tableMeta.rowData)

                  }}>
                    Criar
                  </Button>
                </>
              )
            }
          }
        }]}
        data={clientes.data} 
        />
      <Modal
        parametrosOcultos={[{ ClientId: localStorage.getItem('@ClientId:SmarttipsAdmin')}]}
        campos={[{ NomeRoleta: "Nome da Roleta", LinkRoleta: "Link da Roleta" }]}
        nome="Adicionar Cliente"
        open={adicionarModal}
        onClose={() => HandleAdicionarModal()}
        Metodo={AddCliente}
      />

      {
        clienteSendoEditado.editData !== undefined && 
        <Modal
          campos={clienteSendoEditado.objCliente}
          EditData={clienteSendoEditado.editData}
          nome="Editar Cliente"
          open={clienteSendoEditado.editData !== undefined}
          onClose={() => setClienteSendoEditado({})}
          Metodo={EditCliente}
        />
      }
      
     
    </>
  )
}

export default Replicador;
