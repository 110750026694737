import styled from 'styled-components'

export const ContainerSidebarDesktop = styled.div`
  height: 100%;
  padding: 24px 0;
  border-right: 1px solid #2f2f39;
  box-sizing: border-box;
  padding: 12px;
  @media (max-width: 992px) {
    display: none;
  }
`
export const ContainerSidebarMobile = styled.div`
  position: absolute;
  height: 56px;
  padding: 16px 0;
  @media (min-width: 992px) {
    display: none;
  }
`
