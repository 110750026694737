import axios from 'axios'
axios.defaults.headers.get['Content-Type'] ='application/json;charset=utf-8';
axios.defaults.headers.get['Access-Control-Allow-Origin'] = '*';

export const GetListaPerguntas = async (ClientId: any) => {
    let url = `https://admin.linerasistemas.com.br/api/Bot/GetPerguntasLead?ClienteId=${ClientId}`

    try{
        const response = await axios.get(url)

        return response.data
    } catch(err) {
        console.log(err)
    }

}

export const DeletarPergunta = async (Id: any) => {
    let url = `https://admin.linerasistemas.com.br/api/Bot/DeletePergunta/${Id}`

    const response = await axios.delete(url, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      
      return response;
}

export const UpdatePergunta = async (TextoPergunta: any, IndexState: any, Id: any) => {
    let url = `https://admin.linerasistemas.com.br/api/Bot/UpdatePerguntasLead?TextoPergunta=${TextoPergunta}&IndexState=${IndexState}&Id=${Id}`

    const response = await axios.post(url, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      
      return response;
}

export const InsertPergunta = async (IndexState: any, TextoPergunta: any, ClientId: any) => {
    let url = `https://admin.linerasistemas.com.br/api/Bot/InsertPerguntasLead?IndexState=${IndexState}&TextoPergunta=${TextoPergunta}&ClienteId=${ClientId}`

    const response = await axios.post(url, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      
      return response;
}