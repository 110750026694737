import { ReactElement, createContext, useContext, useEffect, useState } from 'react'
import { noop } from '../utils'

export interface ISinaisContext {
	sinais: any,
	setSinais: (sinais: any) => void,
	adicionarModal: boolean,
	sinalSendoEditado: any,
	setSinalSendoEditado: (g: {}) => void,
	HandleAdicionarModal: () => void,
	AddSinal: (objSinal: any) => void,
	EditSinalData: (dadosSinal: any) => void,
	EditSinal: any,
	parametrosDelete: any,
	setParametrosDelete: (parametros: any) => void,
	GetSinais: () => void;
}

export interface ISinaisContextState {
	children: ReactElement
}

const defaultState: ISinaisContext = {
	sinais: {
		"columns": ["Loading", "Loading", "Loading", "Loading"],
		"data": [
			["Loading", "Loading", "Loading", "Loading"],
		]
	},
	setSinais: noop,
	adicionarModal: false,
	HandleAdicionarModal: noop,
	sinalSendoEditado: {},
	setSinalSendoEditado: noop,
	AddSinal: noop,
	EditSinalData: noop,
	EditSinal: noop,
	setParametrosDelete: noop,
	parametrosDelete: null,
	GetSinais: noop,
}

export const SinaisContext = createContext<ISinaisContext>(defaultState)
SinaisContext.displayName = 'SinaisContext'

export const SinaisProvider = ({
	children,
}: ISinaisContextState): JSX.Element => {
	const [sinais, setSinais] = useState<{}>(defaultState.sinais)
	const [adicionarModal, setAdicionarModal] = useState<boolean>(defaultState.adicionarModal)
	const [sinalSendoEditado, setSinalSendoEditado] = useState<any>(defaultState.sinalSendoEditado)
	const [parametrosDelete, setParametrosDelete] = useState<any>(defaultState.parametrosDelete)

	const GetSinais = () => {
		return {
			"columns": ["Name", "Company", "City", "State"],
			"data": [
				["Joe James", "Test Corp", "Yonkers", "NY"],
				["John Walsh", "Test Corp", "Hartford", "CT"],
				["Bob Herm", "Test Corp", "Tampa", "FL"],
				["James Houston", "Test Corp", "Dallas", "TX"],
			]
		}

		//axios.get('http://localhost:5000/api/clientes')
	}

	const EditSinalData = (dadosSinais: any) => {
		let objSinal: any = [];
		let editData: any = {};

		//@ts-ignore
		sinais.columns.forEach((c, index) => {
			objSinal.push({ [c]: c })
		})
		//@ts-ignore
		sinais.columns.forEach((c, index) => {
			editData[c] = dadosSinais[index]
		})

		setSinalSendoEditado({ objSinal, editData })
	}

	const EditSinal = (objSinal: {}) => {
		console.log(objSinal)
	}

	const DeleteCliente = () => {

	}

	const AddSinal = (objSinal: {}) => {
		console.log(objSinal)

		//axios.post('http://localhost:5000/api/clientes', objCliente)
	}

	const HandleAdicionarModal = () => {
		setAdicionarModal(!adicionarModal)
	}

	

	return (
		<SinaisContext.Provider value={{
			sinais,
			setSinais,
			adicionarModal,
			sinalSendoEditado,
			setSinalSendoEditado,
			HandleAdicionarModal,
			AddSinal,
			EditSinalData,
			EditSinal,
			setParametrosDelete,
			parametrosDelete,
			GetSinais
		}}>
			{children}
		</SinaisContext.Provider>
	)
}

export const useSinais = (): ISinaisContext => useContext(SinaisContext)
