import styled from 'styled-components';

export const Container = styled.div`
    padding: 20px;
`;

export const DivPai = styled.div`
    width: 80vw;
    @media screen and (max-width: 770px){
        width: auto;
    }
`;