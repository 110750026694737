import React, { useEffect } from 'react'
import Login from '../../components/CardLogin'
import { ContainerLogin} from './styles'
import { useAuth } from '../../context/auth.context'

const Loginpage: React.FC = () => {
  useEffect(()=>{
    const verifAuth = () => {
      const verifToken = localStorage.getItem('@Token:SmarttipsAdmin')
      if (verifToken && verifToken != '' && verifToken != undefined && verifToken != null) {
        window.location.href = './'
      }
    }

    verifAuth()
  }, [])

  return (
    <>
      <ContainerLogin>
        <Login/>
        </ContainerLogin>
    </>
  )
}

export default Loginpage
