import React, { useEffect, useState, createContext } from 'react'
import CircleRoundedIcon from '@mui/icons-material/CircleRounded'
import { faker } from '@faker-js/faker/locale/pt_BR'
import {
  CardMonitor,
  CardStatusA,
  CardStatusB,
  ContainerIMG,
  ContainerMonitor,
  TextoAtt,
  TextoNome,
  TextStatus,
  TextStatusB,
} from './styles'
import { GetInfosGreenDinamico, GetTodosJogosMonitor } from '../../services/Roleta'
import { useNavigate, useParams } from 'react-router-dom'
import { Button } from '@mui/material';



const Monitor: React.FC = () => {
  const [jogos, setJogos] = useState([]);
  const {chatId} = useParams()  
  const [greenRed, setGreenRed] = useState()
  const navigate = useNavigate()

  
  useEffect(() => {
    const fetchMonitorJogos = async () => {
      try {
        const data = await GetTodosJogosMonitor(chatId);
        setJogos(data);
        console.log(data);
      } catch (error) {
        console.error("Erro ao buscar os jogos:", error);
      }
    };
  
    // Executar a função inicialmente
    fetchMonitorJogos();
  
    // Configurar o intervalo para repetir a cada 30 segundos
    const intervalId = setInterval(fetchMonitorJogos, 30000);
  
    // Limpar o intervalo quando o componente for desmontado
    return () => clearInterval(intervalId);
  }, [chatId]);

  useEffect(()=>{
    const fetchGreen = async () => {
      const data = await GetInfosGreenDinamico(chatId)
      setGreenRed(data.roletas)
      
      
    }

    fetchGreen()
    console.log(greenRed)
  }, [chatId])
    

  return ( 
    <>
    {greenRed && 
      <header style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingLeft: '30px', paddingRight: '30px'}}>
        <div style={{display: 'flex', alignItems: 'center', gap: '50px'}}>
          <span style={{color: '#FFF', display: 'flex', alignItems: 'center', gap: '5px', fontWeight: 'bold'}}>
            <div style={{backgroundColor: 'green', width: '20px', height: '20px', borderRadius: '50px'}}></div>
            {greenRed[0]["green1"]}
          </span>
          
          <span style={{color: '#FFF', display: 'flex', alignItems: 'center', gap: '5px', fontWeight: 'bold'}}>
            <div style={{backgroundColor: 'red', width: '20px', height: '20px', borderRadius: '50px'}}></div>
            {greenRed[0]["red1"]}
          </span>
        </div>

        <Button variant="contained" onClick={()=>{
          navigate('../monitor')
        }}>
          Voltar
        </Button>

      </header>
    }
      
      <ContainerMonitor>
        
        {jogos.map(jogo => (
          
          <CardMonitor>
            <div style={{width: '10px', height: '10px', borderRadius: '50px', 
            background: jogo[3] == 'True' ? '#41B878' : '#ef2f3c', 
            position: 'absolute', right: '10px', top: '10px'}}></div>
          <ContainerIMG>
              <img src={faker.image.imageUrl(50, 50, 'apostas', true)} style={{borderRadius: '50px'}} />
            </ContainerIMG>
            <TextoNome>{jogo[0]}</TextoNome>
            <TextoAtt>Ultima Atualização: {jogo[2]}</TextoAtt>
            <CardStatusB 
            style={{
               background: jogo[3]  == 'True' ? "#41B87870" : "#ef2f3c"
              }}>
              <TextStatusB style={{
                color: jogo[3] == 'True' ? '#41B878' : '#ef2f3c',
                fontWeight: 'bold',
                fontSize: '16px'
              }}>{jogo[3] == 'True' ? "Ativo" : "Inativo"}</TextStatusB>
            </CardStatusB>
          </CardMonitor>
     
        ))}
      </ContainerMonitor>
    </>
  )
}

export default Monitor
