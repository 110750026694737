import { ReactElement, createContext, useContext, useEffect, useState } from 'react'
import { noop } from '../utils'
import { GetListaClientes, DeleteUsuario, InsertUsuarioApp } from '../services/criarCliente';

export interface IClientesContext {
	clientes: any,
	setClientes: (clientes: any) => void,
	adicionarModal: boolean,
	clienteSendoEditado: any,
	setClienteSendoEditado: (c: {}) => void,
	HandleAdicionarModal: () => void,
	DeleteCliente: any
	AddCliente: (objCliente: any) => void,
	EditClienteData: (dadosCleiente: any) => void,
	EditCliente: any,
	parametrosDelete: any,
	setParametrosDelete: (parametros: any) => void,
	GetClientes: (clientid: any) => void;
}

export interface IClientesContextState {
	children: ReactElement
}

const defaultState: IClientesContext = {
	clientes: {
		"columns": ["Loading", "Loading", "Loading", "Loading"],
		"data": [
			["Loading", "Loading", "Loading", "Loading"],
		]
	},
	setClientes: noop,
	adicionarModal: false,
	HandleAdicionarModal: noop,
	clienteSendoEditado: {},
	setClienteSendoEditado: noop,
	AddCliente: noop,
	EditClienteData: noop,
	EditCliente: noop,
	setParametrosDelete: noop,
	parametrosDelete: null,
	GetClientes: noop,
	DeleteCliente: noop,
	
}

export const ClientesContext = createContext<IClientesContext>(defaultState)
ClientesContext.displayName = 'ClientesContext'

export const ClientesProvider = ({
	children,
}: IClientesContextState): JSX.Element => {
	const [clientes, setClientes] = useState<{}>(defaultState.clientes)
	const [adicionarModal, setAdicionarModal] = useState<boolean>(defaultState.adicionarModal)
	const [clienteSendoEditado, setClienteSendoEditado] = useState<any>(defaultState.clienteSendoEditado)
	const [parametrosDelete, setParametrosDelete] = useState<any>(defaultState.parametrosDelete)

	const GetClientes = async (clientId: any) => {
		const response = await GetListaClientes(clientId)
		setClientes(response)

		//axios.get('http://localhost:5000/api/clientes')
	}

	const EditClienteData = (dadosClientes: any) => {
		let objCliente: any = [];
		let editData: any = {};

		//@ts-ignore
		clientes.columns.forEach((c, index) => {
			objCliente.push({ [c]: c })
		})
		//@ts-ignore
		clientes.columns.forEach((c, index) => {
			editData[c] = dadosClientes[index]
		})

		setClienteSendoEditado({ objCliente, editData })
	}

	const EditCliente = (objCliente: {}) => {
		console.log(objCliente)
	}

	const DeleteCliente = async (id: any) => {
		console.log(parametrosDelete["id"], 'id')
		const response = await DeleteUsuario(parametrosDelete["id"])
		.then((response)=>{
			alert('Usuário removido com sucesso!')
			setParametrosDelete(null)
		}).catch((err)=>{
			alert(err)
		});
		return response
	}

	const AddCliente = async (objCliente: any) => {
		console.log(objCliente)
		//Email: any, Nome: any, Senha: any, SubDominio: any, Telefone: any
		//cadusuario
		const response = await InsertUsuarioApp(objCliente["Email"], objCliente["Nome"], objCliente["Senha"], objCliente["SubDominio"], objCliente["Telefone"])
		.then(()=>{
			alert("Usuário criado com sucesso!")
		}).catch((err) => {
			alert("Ocorreu um erro.")
		})
		setAdicionarModal(!adicionarModal)
		return response
		//axios.post('http://localhost:5000/api/clientes', objCliente)
	}

	const HandleAdicionarModal = () => {
		setAdicionarModal(!adicionarModal)
	}


	return (
		<ClientesContext.Provider value={{
			clientes,
			setClientes,
			adicionarModal,
			clienteSendoEditado,
			setClienteSendoEditado,
			HandleAdicionarModal,
			AddCliente,
			EditClienteData,
			EditCliente,
			setParametrosDelete,
			parametrosDelete,
			GetClientes,
			DeleteCliente
			
		}}>
			{children}
		</ClientesContext.Provider>
	)
}

export const useClientes = (): IClientesContext => useContext(ClientesContext)
