import * as React from 'react'
import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import logo from '../../../assets/LOGO4.png'
import { useNavigate } from "react-router-dom";
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined'
import { LogoContainer } from './styles'
import { useAuth } from '../../../context/auth.context'
import { useEffect, useState } from 'react'
import ScreenSearchDesktopIcon from '@mui/icons-material/ScreenSearchDesktop';
import GroupIcon from '@mui/icons-material/Group';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import LinkIcon from '@mui/icons-material/Link';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';

const MenuLateral: React.FC = () => {
  const [open, setOpen] = React.useState(false)
  const [drawerOpen, setDrawerOpen] = React.useState(false)
  const [permissao, setPermissao] = useState<any>(null)

  const [activeTab, setActiveTab] = useState<any>(0)

  const [leads, setLeads] = useState<any>(null)
  const [grupos, setGrupos] = useState<any>(null)
  const [clientes, setClientes] = useState<any>(null)
  const [criarwebapp, setCriarWebApp] = useState<any>(null)
  const [replicador, setReplicador] = useState<any>(null)
  const [links, setLinks] = useState<any>(null)
  const [perguntabot, setPerguntabot] = useState<any>(null)
  const [criartelegram, setCriartelegram] = useState<any>(null)
  const [criarbot, setCriarbot] = useState<any>(null)
  const [monitor, setMonitor] = useState<any>(null)
  const navigate = useNavigate();

  const {perm, permissoes, setPermissoes} = useAuth()

  useEffect(()=>{
    perm('')
  }, [window.onload])

  useEffect(()=>{
    if(permissoes){
      if(permissoes.data){
        setLeads(permissoes.data[0][2])
        setGrupos(permissoes.data[1][2])
        setClientes(permissoes.data[2][2])
        setCriarWebApp(permissoes.data[3][2])
        setReplicador(permissoes.data[4][2])
        setLinks(permissoes.data[5][2])
        setPerguntabot(permissoes.data[6][2])
        setCriartelegram(permissoes.data[7][2])
        setCriarbot(permissoes.data[8][2])
        setMonitor(permissoes.data[9][2])
        
      }
    }

  }, [permissoes])

  useEffect(()=>{
    console.log(typeof leads)
  }, [leads])

  //permissoes e suas ordens
  //1 - leads
  //2 - grupos
  //3 - clientes
  //4 - criarwebapp
  //5 - replicador
  //6 - links
  //7 - perguntabot
  //8 - criartelegram
  //9 - criarbot
  

  const handleClick = (): void => {
    setOpen(!open)
  }

  return (
    <List
      sx={{ width: '100%' }}
      component="nav"
      aria-labelledby="nested-list-subheader"
      subheader={
        <LogoContainer>
          <img style={{ width: '100%', padding: '16px', paddingRight: '80px', marginTop: '-20px' }} src={logo} />
        </LogoContainer>
      }
    >

      {leads == "True" && 
        <ListItemButton onClick={() => {
          navigate ("/")
          setActiveTab(0)
          }} style={{borderLeft: activeTab == 0 ? '5px solid #E2C03C' : 'none'}}>
          <ListItemIcon >
            <LeaderboardIcon style={{ color: '#FFF' }} />
          </ListItemIcon>
          <ListItemText primary="Leads" />
        </ListItemButton>
      }
        
      {grupos == "True" && 
        <ListItemButton  onClick={() => {
          navigate ("/grupos")
          setActiveTab(1)
          }} style={{borderLeft: activeTab == 1 ? '5px solid #E2C03C' : 'none'
          }}>
          <ListItemIcon>
            <GroupIcon style={{ color: '#FFF' }} />
          </ListItemIcon>
          <ListItemText primary="Grupos" />
        </ListItemButton>
      }
      

      {clientes == "True" &&
        <ListItemButton onClick={() => {
          navigate ("/clientes")
          setActiveTab(2)
        }} style={{borderLeft: activeTab == 2 ? '5px solid #E2C03C' : 'none'
        }}>
          <ListItemIcon>
            <DashboardOutlinedIcon style={{ color: '#FFF' }} />
          </ListItemIcon>
          <ListItemText primary="Clientes" />
        </ListItemButton>
      }
      

      {criarwebapp == "True" &&
        <ListItemButton onClick={() => {
          navigate ("/criarwebapp")
          setActiveTab(3)
        }} style={{borderLeft: activeTab == 3 ? '5px solid #E2C03C' : 'none'
        }}>
          <ListItemIcon>
            <AppRegistrationIcon style={{ color: '#FFF' }} />
          </ListItemIcon>
          <ListItemText primary="Criar Web App" />
        </ListItemButton> 
      }

      {replicador == "True" && 
        <ListItemButton onClick={() => {
          navigate ("/replicador")
          setActiveTab(4)
        }} style={{borderLeft: activeTab == 4 ? '5px solid #E2C03C' : 'none'
        }}>
          <ListItemIcon>
            <CopyAllIcon style={{ color: '#FFF' }} />
          </ListItemIcon>
          <ListItemText primary="Replicador" />
        </ListItemButton>
      }

      {criarbot == "True" && 
        <ListItemButton onClick={() => {
          navigate ("/criarbot")
          setActiveTab(5)
        }} style={{borderLeft: activeTab == 5 ? '5px solid #E2C03C' : 'none'
        }}>
          <ListItemIcon>
            <SmartToyIcon style={{ color: '#FFF' }} />
          </ListItemIcon>
          <ListItemText primary="Criar Bot" />
        </ListItemButton> 
      }

      {links == "True" && 
        <ListItemButton onClick={() => {
          navigate ("/links")
          setActiveTab(6)
        }} style={{borderLeft: activeTab == 6 ? '5px solid #E2C03C' : 'none'
        }}>
        <ListItemIcon>
          <LinkIcon style={{ color: '#FFF' }} />
        </ListItemIcon>
        <ListItemText primary="Links" />
      </ListItemButton>
      }
      
      {perguntabot == "True" &&
        <ListItemButton onClick={() => {
          navigate ("/perguntasbot")
          setActiveTab(7)
        }} style={{borderLeft: activeTab == 7 ? '5px solid #E2C03C' : 'none'
        }}>
          <ListItemIcon>
            <QuestionAnswerIcon style={{ color: '#FFF' }} />
          </ListItemIcon>
          <ListItemText primary="Perguntas Bot" />
        </ListItemButton>
      }
       

      {/* {criartelegram == "True" && 
        <ListItemButton onClick={() => {
          navigate ("/criartelegram")
          setActiveTab(8)
        }} style={{borderLeft: activeTab == 8 ? '5px solid #E2C03C' : 'none'
        }}>
          <ListItemIcon>
            <DashboardOutlinedIcon style={{ color: '#FFF' }} />
          </ListItemIcon>
          <ListItemText primary="Criar Telegram" />
        </ListItemButton>
      } */}

      {monitor == "True" && 
        <ListItemButton onClick={() => {
          navigate ("/monitor")
          setActiveTab(9)
        }} style={{borderLeft: activeTab == 9 ? '5px solid #E2C03C' : 'none',
        
        }}>
          <ListItemIcon>
            <ScreenSearchDesktopIcon style={{ color: '#FFF' }} />
          </ListItemIcon>
          <ListItemText primary="Monitor" />
        </ListItemButton>
      }
        

      

      {/* <ListItemButton onClick={() => navigate ("/casasDeApostas")}>
        <ListItemIcon>
          <DashboardOutlinedIcon style={{ color: '#FFF' }} />
        </ListItemIcon>
        <ListItemText primary="Casas de Apostas" />
      </ListItemButton> */}

     

      {/* <ListItemButton onClick={() => navigate ("/sinais")}>
        <ListItemIcon>
          <DashboardOutlinedIcon style={{ color: '#FFF' }} />
        </ListItemIcon>
        <ListItemText primary="Sinais" />
      </ListItemButton> */}
      <style>
       
      </style>
      
    </List>
  )
}

export default MenuLateral
