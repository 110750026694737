import axios from 'axios'
axios.defaults.headers.get['Content-Type'] ='application/json;charset=utf-8';
axios.defaults.headers.get['Access-Control-Allow-Origin'] = '*';

export const GetInfoMesas = async () => {
    try{
        const response = await axios.get(`https://admin.linerasistemas.com.br/api/EstadoRoleta/GetInfoMesas`)

        return response.data;
    } catch (error) {
        console.log(error)
    }
}
        
export const GetTodasCondicionais = async () => {
    try{
        const response = await axios.get(`https://admin.linerasistemas.com.br/api/EstadoRoleta/GetTodasCondicionais`)

        return response.data;
    } catch (error) {
        console.log(error)
    }
}

export const GetAtivoStatus = async () => {
    try{
        const response = await axios.get(`https://admin.linerasistemas.com.br/api/EstadoRoleta/GetAtivoStatus`)

        return response.data;

    } catch (error) {
        console.log(error)
    }
}

export const GetTodasRoletas = async () => {
    try{
        const response = await axios.get(`https://admin.linerasistemas.com.br/api/EstadoRoleta/GetTodasRoletas`)

        return response.data;
    } catch (error) {
        console.log(error)
    }
}

export const GetTodosGrupos = async (clientId: any) => {
    try{
        const response = await axios.get(`https://admin.linerasistemas.com.br/api/Monitor/GetUltimasEntradas?ClienteId=${clientId}`)

        return response.data;
    } catch (error) {
        console.log(error)
    }
}
//lalalal
export const GetTodosJogosMonitor = async (chatId: any) => {
    let url = `https://admin.linerasistemas.com.br/api/Monitor/GetTodosJogosMonitor?ChatId=${chatId}`;

    try{
        const response = await axios.get(url)

        return response.data;
    } catch (error) {
        console.log(error)
    }
}

export const GetInfosGreenDinamico = async (chatId: any) => {
    let url = `https://admin.linerasistemas.com.br/api/Monitor/GetInfosGreenDinamico?ChatId=${chatId}`;

    try{
        const response = await axios.get(url)

        return response.data;
    } catch (error) {
        console.log(error)
    }
}



export const GetInfosDashboard = async (provider: string | null, tipoMesa: number | null, dataInicial: string | null, dataFinal: string | null, grupo: number | null) => {
        let url = `https://admin.linerasistemas.com.br/api/Monitor/GetInfosDashboard`;

        let verifBack = false;

        if(provider != null && !(verifBack)){
            url += `?provider=${provider}`
            verifBack = true;
        } else if(provider != null && verifBack){
            url += `&provider=${provider}`
        }

        if(tipoMesa != null && !(verifBack)){
            url += `?tipoMesa=${tipoMesa}`
            verifBack = true;
        } else if(tipoMesa != null && verifBack){
            url += `&tipoMesa=${tipoMesa}`
        }

        if(dataInicial != null && !(verifBack)){
            url += `?dataInicial=${dataInicial}`
            verifBack = true;
        } else if(dataInicial != null && verifBack){
            url += `&dataInicial=${dataInicial}`
        }

        if(dataFinal != null && !(verifBack)){
            url += `?dataFinal=${dataFinal}`
            verifBack = true;
        } else if(dataFinal != null && verifBack){
            url += `&dataFinal=${dataFinal}`
        }

        console.log(url)

   
    try{
        const response = await axios.get(url)

        return response.data;
    } catch (error) {
        console.log(error)
    }

    // infoRoleta[0] = r["Analise"].ToString();
    // infoRoleta[1] = r["Confirmacao"]
    // infoRoleta[2] = r["Green"].ToString();
    // infoRoleta[3] = r["Red"].ToString();
    // infoRoleta[4] = r["Gale1"].ToString();
    // infoRoleta[5] = r["Gale2"].ToString();
    // infoRoleta[6] = r["Gale3"].ToString();
}


