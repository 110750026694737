import { ReactElement, createContext, useContext, useEffect, useState } from 'react'
import { noop } from '../utils'
import { GetListaClientes, DeleteUsuario } from '../services/criarCliente';
import { DeleteBotLink, GetLinksTelegram, InsertLinkBot, UpdateBotLink } from '../services/leads';

export interface ILinksContext {
	links: any,
	setLinks: (links: any) => void,
	adicionarModal: boolean,
	linkSendoEditado: any,
	setLinkSendoEditado: (c: {}) => void,
	HandleAdicionarModal: () => void,
	DeleteLink: any
	AddLink: (objLink: any) => void,
	EditLinkData: (dadosCleiente: any) => void,
	EditLink: any,
	parametrosDelete: any,
	setParametrosDelete: (parametros: any) => void,
	GetLinks: any;
}

export interface ILinksContextState {
	children: ReactElement
}

const defaultState: ILinksContext = {
	links: {
		"columns": ["Loading", "Loading", "Loading", "Loading"],
		"data": [
			["Loading", "Loading", "Loading", "Loading"],
		]
	},
	setLinks: noop,
	adicionarModal: false,
	HandleAdicionarModal: noop,
	linkSendoEditado: {},
	setLinkSendoEditado: noop,
	AddLink: noop,
	EditLinkData: noop,
	EditLink: noop,
	setParametrosDelete: noop,
	parametrosDelete: null,
	GetLinks: noop,
	DeleteLink: noop,
	
}

export const LinksContext = createContext<ILinksContext>(defaultState)
LinksContext.displayName = 'LinksContext'

export const LinksProvider = ({
	children,
}: ILinksContextState): JSX.Element => {
	const [links, setLinks] = useState<{}>(defaultState.links)
	const [adicionarModal, setAdicionarModal] = useState<boolean>(defaultState.adicionarModal)
	const [linkSendoEditado, setLinkSendoEditado] = useState<any>(defaultState.linkSendoEditado)
	const [parametrosDelete, setParametrosDelete] = useState<any>(defaultState.parametrosDelete)

	const GetLinks = async () => {
		const response = await GetLinksTelegram(localStorage.getItem('@ClientId:SmarttipsAdmin'))
		
		setLinks(response)

		return response
	}

	const EditLinkData = (dadosLinks: any) => {
		let objLink: any = [];
		let editData: any = {};

		//@ts-ignore
		links.columns.forEach((c, index) => {
			objLink.push({ [c]: c })
		})
		//@ts-ignore
		links.columns.forEach((c, index) => {
			editData[c] = dadosLinks[index]
		})

		setLinkSendoEditado({ objLink, editData })
	}

	const EditLink = async (objLink: any) => {

		console.log(objLink)

		const response = await UpdateBotLink(objLink["Nome do Grupo"], objLink["Link do Grupo"], localStorage.getItem('@ClientId:SmarttipsAdmin'), objLink["Id"]).then(()=>{
			alert('Link atualizado com sucesso!')
		}).catch((err)=>{
			alert(err)
		})

		setLinkSendoEditado({})
		return response

	}

	const DeleteLink = async (id: any) => {
		console.log(parametrosDelete["id"], 'id')
		const response = await DeleteBotLink(parametrosDelete["id"])
		.then((response)=>{
			alert('Usuário removido com sucesso!')
			setParametrosDelete(null)
		}).catch((err)=>{
			alert(err)
		});
		return response
	}

	const AddLink = async (objLink: any) => {
		console.log(objLink)
		//ClientId: any, TextoGrupo: any, LinkGrupo: any, AfiliadoId: any
		const response = await InsertLinkBot(objLink["ClientId"], objLink["TextoGrupo"], objLink["LinkGrupo"], objLink["AfiliadoId"])
		.then(()=>{
			alert("Link do grupo adicionado ao bot")
		}).catch((err)=>{
			alert("Ocorreu um erro.")
		})
		//ConfiguracaoBotLead_Grupos
		setAdicionarModal(!adicionarModal)
		//axios.post('http://localhost:5000/api/links', objLink)
	}

	const HandleAdicionarModal = () => {
		setAdicionarModal(!adicionarModal)
	}


	return (
		<LinksContext.Provider value={{
			links,
			setLinks,
			adicionarModal,
			linkSendoEditado,
			setLinkSendoEditado,
			HandleAdicionarModal,
			AddLink,
			EditLinkData,
			EditLink,
			setParametrosDelete,
			parametrosDelete,
			GetLinks,
			DeleteLink
			
		}}>
			{children}
		</LinksContext.Provider>
	)
}

export const useLinks = (): ILinksContext => useContext(LinksContext)
