import axios from 'axios'
axios.defaults.headers.get['Content-Type'] ='application/json;charset=utf-8';
axios.defaults.headers.get['Access-Control-Allow-Origin'] = '*';

export const GetChatIdGrupo = async (clientId: any) => {
    try{
        const response = await axios.get(`https://admin.linerasistemas.com.br/api/NovoCliente/GetChatIdGrupo?ClienteId=${clientId}`)

        return response.data;

    } catch (error) {
        console.log(error)
    }
}

export const DeletarGrupo = async (IdChat: any) => {
    let url = `https://admin.linerasistemas.com.br/api/NovoCliente/DeleteGrupo/${IdChat}`

    const response = await axios.delete(url, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
    
    return response
}


export const InsertGrupo = async (ClientId: any, NomeGrupo: any, DescricaoGrupo: any, ChatId: any) => {
    let url = `https://admin.linerasistemas.com.br/api/NovoCliente/InsertGrupo?ClienteId=${ClientId}&NomeGrupo=${NomeGrupo}&DescricaoGrupo=${DescricaoGrupo}&ChatId=${ChatId}`
  
        // api/Lead/InsertLead  
          // lead: {
          // nome: any,
          // email: any,
          // telefone: any,
          // clientId: any
          // }
  
    const response = await axios.post(url, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
    
    return response;
  
  }

