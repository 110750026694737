import React, { ReactElement } from "react";
import { Input, ModalBody, ModalButton, ModalButtonPrimary, ModalContainer, ModalFooter, ModalHeader, ModalMui, ModalTitle } from "./styles";
import { Button } from "@mui/material";
import { useForm, SubmitHandler } from "react-hook-form"

interface IProps {
    open: boolean;
    onClose: () => void;
    children?: ReactElement;
    nome: string;
    mensagem?: string;
    campos?: any[];
    parametrosOcultos?: [{}];
    Metodo?: any;
    EditData?: any;
    Delete?: boolean;
}

const Modal: React.FC<IProps> = ({ open, onClose, children, nome, campos, parametrosOcultos, Metodo = () => {}, EditData, Delete = false }) => {

    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm<any>({
        defaultValues: EditData
    })

    const onSubmit: SubmitHandler<any> = (data) => Metodo(data)

    return (
        <ModalMui open={open} onClose={onClose}>
            <ModalContainer>
                <ModalHeader>
                    <ModalTitle>
                        {nome}
                    </ModalTitle>
                </ModalHeader>
                {
                    Delete && (
                        <>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <ModalBody>
                                Tem certeza que deseja deletar?
                            </ModalBody>
                            <ModalFooter>
                                <Button onClick={onClose}>
                                    Cancelar
                                </Button>
                                <Button type="submit" variant="contained">
                                    Deletar
                                </Button>
                            </ModalFooter>
                        </form>
                        </>
                    )
                }
                {
                    children && (
                        <>
                            {children}
                        </>
                    )
                }

                {campos && campos.length > 0 &&
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <ModalBody>
                            {campos.map((obj, index) => {
                                const propertyNames = Object.keys(obj);
                                return propertyNames.map((propertyName) => (
                                    <Input
                                        key={`${index}-${propertyName}`}
                                        {...register(propertyName)}
                                        placeholder={(obj as { [key: string]: any })[propertyName]}
                                    />
                                ));
                            })}
                            {parametrosOcultos && parametrosOcultos.length > 0 &&
                                <>
                                    {parametrosOcultos.map((obj, index) => {
                                        const propertyNames = Object.keys(obj);
                                        return propertyNames.map((propertyName) => (
                                            <Input
                                                style={{ display: "none" }}
                                                key={`${index}-${propertyName}`}
                                                {...register(propertyName)}
                                                placeholder={propertyName}
                                                value={(obj as {[key: string]: any})[propertyName]}
                                            />
                                        ));
                                    })}
                                </>
                            }
                        </ModalBody>
                        <ModalFooter>
                            <Button onClick={onClose}>
                                Cancelar
                            </Button>
                            <Button type="submit" variant="contained">
                                Salvar
                            </Button>
                        </ModalFooter>
                    </form>
                }
            </ModalContainer>
        </ModalMui >
    )
}

export default Modal;