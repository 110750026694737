import CrudTopbar from "../../components/Crud/crud-topbar";
import CrudTabela from "../../components/Crud/crud-tabela";
import Modal from "../../components/Modal";
import { Button } from "@mui/material";
import { useEffect } from "react";
import { useAuth } from "../../context/auth.context";
import { useCriarBot } from "../../context/criarBot.context";
import {DivPai} from './styles'

const CriarBot = () => {
  const { HandleAdicionarModal, HandleAdicionarModal2, adicionarModal, adicionarModal2, bots, parametrosDelete, setParametrosDelete, GetBots, setBots, CriarBot, ConfigurarGrupo, EditBotData, setBotSendoEditado, botSendoEditado, EditBot, EditLinksData, linkSendoEditado, setLinkSendoEditado, EditLinks, GetTelegram, telegram, CriarLink, DeleteBot } = useCriarBot();

  useEffect(()=>{
    const verifAuth = () => {
      const verifToken = localStorage.getItem('@Token:SmarttipsAdmin')
      if (verifToken == null || verifToken == '') {
        window.location.href = './login'
      }
    }
    verifAuth()
    GetTelegram()
  }, [])

  useEffect(() => {
	  GetBots()
	}, [])

  //fazer insert de link com o metodo ConfigurarGrupo

  return (
    <>
    <DivPai>
      <CrudTopbar nomeDaPagina="Criar Bot" textoButton="Criar Bot" onClick={() => HandleAdicionarModal()} />
      {/* <CrudTopbar nomeDaPagina="Adicionar Link" textoButton="Adicionar Link" onClick={() => HandleAdicionarModal2()} /> */}
    </DivPai>

      <CrudTabela
              nome="Meus Bots"
              columns={[...bots.columns, {
                label: "Ações",
                options: {
                  customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
                    return (
                      <>
                        <Button onClick={() => {
                          console.log(value, tableMeta)
                          EditBotData(tableMeta.rowData)

                        }}>
                          Personalizar
                        </Button>
                        {/* <Button onClick={() => {
                          console.log(value, tableMeta)
                          EditLinksData(telegram)
                        }}>
                          Ver Links
                        </Button> */}
                        <Button onClick={() => {
                          console.log(tableMeta.rowData[4])
                          setParametrosDelete({ id: tableMeta.rowData[4] })
                        }}>
                          Remover
                        </Button>
                      </>
                    )
                  }
                }
              }]}
              data={bots.data} 
              />

      {
        botSendoEditado.editData !== undefined && <Modal
          campos={botSendoEditado.objBot}
          EditData={botSendoEditado.editData}
          nome="Personalizar Bot"
          open={botSendoEditado.editData !== undefined}
          onClose={() => setBotSendoEditado({})}
          Metodo={EditBot}
        />
      }

      {
        linkSendoEditado.editData !== undefined &&
        <Modal
          campos={linkSendoEditado.objLinks}
          EditData={linkSendoEditado.editData}
          nome="Links"
          open={linkSendoEditado.editData !== undefined}
          onClose={() => setLinkSendoEditado({})}
          Metodo={EditLinks}
        />
      }


      
      {
        parametrosDelete !== null && 
        <Modal
          Delete
          nome="Deletar Bot"
          open={parametrosDelete !== null}
          onClose={() => setParametrosDelete(null)}
          Metodo={DeleteBot}
        />
      } 

      <Modal
        parametrosOcultos={[{ afiliadoId: localStorage.getItem('@ClientId:SmarttipsAdmin')}]}
        campos={[{ textoAcao: "Texto Açao", nomeBot: "Nome do Bot"}]}
        nome="Criar Bot"
        open={adicionarModal}
        onClose={() => HandleAdicionarModal()}
        Metodo={CriarBot}
      /> 

<Modal
        parametrosOcultos={[{ clientId: localStorage.getItem('@ClientId:SmarttipsAdmin'), afiliadoId: localStorage.getItem('@ClientId:SmarttipsAdmin')}]}
        campos={[{ textoGrupo: "Texto", linkGrupo: "Link"}]}
        nome="Criar Bot"
        open={adicionarModal2}
        onClose={() => HandleAdicionarModal2()}
        Metodo={CriarLink}
      /> 

    </>
  )
}

export default CriarBot;
