import React from 'react'
import { ContainerLogin} from './styles'
import CardRecuperarSenha from '../../components/CardRecuperarSenha'

const RecuperarSenha: React.FC = () => {
  return (
    <>
      <ContainerLogin>
        <CardRecuperarSenha/>
    </ContainerLogin>
    </>
  )
}

export default RecuperarSenha
