import * as React from 'react'
import { Avatar, Badge, IconButton, Menu, MenuItem } from '@mui/material'
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined'
import { ContainerProfile, ContainerUserBar } from './styles'
import { useNavigate } from 'react-router-dom'

const notificationsLabel = (count: number): string => {
  if (count === 0) {
    return 'no notifications'
  }
  if (count > 99) {
    return 'more than 99 notifications'
  }
  return `${count} notifications`
}

const Userbar: React.FC = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const navigate = useNavigate()
  const handleClick = (event: React.MouseEvent<HTMLDivElement>): void => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = (): void => {
    setAnchorEl(null)
    navigate('./login')
    localStorage.removeItem('@Token:SmarttipsAdmin')
    
    localStorage.removeItem('@UserEmail:SmarttipsAdmin')
    
    localStorage.removeItem('@Responsavel:SmarttipsAdmin')
    
    localStorage.removeItem('@ClientId:SmarttipsAdmin')
    
    
    
  }

  return (
    <>
      <ContainerUserBar>
        <Avatar
          onClick={handleClick}
          style={{
            cursor: 'pointer',
          }}
          sx={{ width: 40, height: 40 }}
          alt="Remy Sharp"
          src="/broken-image.jpg"
        />
        <Menu
          sx={{
            '& ul': {
              padding: 'unset',
            },
          }}
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          <ContainerProfile>
            <MenuItem onClick={handleClose}>Logout</MenuItem>
          </ContainerProfile>
        </Menu>
      </ContainerUserBar>
    </>
  )
}

export default Userbar
