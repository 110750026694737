import { Modal, Input as MuiInput } from '@mui/material';
import styled from 'styled-components';

export const ModalMui = styled(Modal)`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
`;

export const ModalContainer = styled.div`
    width: 100%;
    max-width: 500px;
    height: fit-content;
    max-height: 500px;
    background-color: rgba(0, 0, 0, 0.8);
    border-radius: 0.25rem;
    box-shadow: 0 0 1rem rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;

    * {
        color: #fff;
    }
`;

export const Input = styled(MuiInput)`
    width: 100%;
    margin-bottom: 1rem;
    background-color: #FFF;
    border-radius: 0.25rem;
    padding: 0.5rem 1rem;
    color: #000 !important;
    transition: all 0.2s ease-in-out;
`;

export const ModalHeader = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    border-bottom: 1px solid #ccc;
`;

export const ModalTitle = styled.h2`
    margin: 0;
`;

export const ModalBody = styled.div`
    padding: 1rem;
`;

export const ModalFooter = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    border-top: 1px solid #ccc;
    width: 100%;
`;

export const ModalButton = styled.button`
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 0.25rem;
    background-color: #ccc;
    color: #fff;
    cursor: pointer;
    transition: all 0.2s ease-in-out;

    &:hover {
        background-color: #999;
    }
`;

export const ModalButtonPrimary = styled(ModalButton)`
    background-color: #007bff;

    &:hover {
        background-color: #0069d9;
    }
`;

export const ModalButtonDanger = styled(ModalButton)`
    background-color: #dc3545;

    &:hover {
        background-color: #c82333;
    }
`;

export const ModalButtonSuccess = styled(ModalButton)`
    background-color: #28a745;

    &:hover {
        background-color: #218838;
    }
`;

export const ModalButtonWarning = styled(ModalButton)`
    background-color: #ffc107;

    &:hover {
        background-color: #e0a800;
    }
`;

export const ModalButtonInfo = styled(ModalButton)`
    background-color: #17a2b8;

    &:hover {
        background-color: #138496;
    }
`;