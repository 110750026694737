import React from "react";
import { ButtonAdd, Container } from "./styles";

interface IProps {
    onClick: () => void;
    nomeDaPagina: string;
    textoButton?: string;
}

const CrudTopbar: React.FC<IProps> = ({onClick, nomeDaPagina, textoButton}) => {

    return (
        <Container>
            <h1>{nomeDaPagina}</h1>
            <ButtonAdd onClick={onClick} variant="contained" style={{
            }}>{textoButton || 'Adicionar'}</ButtonAdd>
        </Container>
    )
}

export default CrudTopbar;