import React, { useEffect, useState } from 'react'
import { ContentBackground,PaiLogin,Cardlogin, StyledImage, PaiInput,  StyledInput, InputsDiv, BtLogin, CheckBox, CheckBoxLabel, DivCheckBox, EsqueciMinhaSenha, DivFooterInputs, DivFooterButton} from './styles'
import logo from '.././../assets/LOGO2.png'
import PersonIcon from '@mui/icons-material/Person';
import LockIcon from '@mui/icons-material/Lock';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useAuth } from "../../context/auth.context";

function Cardinput() {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const {login, verifAuth} = useAuth()

  

  function handleUsernameChange(e: { target: { value: React.SetStateAction<string>; }; }) {
    setUsername(e.target.value);
  }

  function handleEmailChange(e: { target: { value: React.SetStateAction<string>; }; }) {
    setEmail(e.target.value);
  }

  function handlePasswordChange(e: { target: { value: React.SetStateAction<string>; }; }) {
    setPassword(e.target.value);
  }

  const handleClick = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    const usuario = {
      nome: username,
      email: email,
      senha: password
    }

    await login(usuario)

  }

 
  
  return (
    <form onSubmit={handleClick}>
      <InputsDiv>
        <PersonIcon 
          style={{
            position: 'absolute',
            left: 0,
            fontSize: '32px',
            color: 'white',
            opacity: '1',
            marginTop: '15px',
            marginLeft: '10px',
          }}
          />
        <StyledInput 
          type="text"
          placeholder="Email"
          value={email}
          onChange={handleEmailChange}
        />
      </InputsDiv>

      <InputsDiv>
        <LockIcon 
          style={{
            position: 'absolute',
            left: 0,
            fontSize: '30px',
            color: 'white',
            opacity: '1',
            marginTop: '15px',
            marginLeft: '10px',
            
          }}
          />
        <StyledInput
          type="password"
          placeholder="SENHA"
          value={password}
          onChange={handlePasswordChange}
        />
      </InputsDiv>

      <DivFooterInputs>

       
      {/* <DivCheckBox>
        <CheckBox/>
        <CheckBoxLabel>Manter-me logado</CheckBoxLabel>
      </DivCheckBox> */}

        <EsqueciMinhaSenha>Esqueci minha senha</EsqueciMinhaSenha>
      </DivFooterInputs>
     
     
      <BtLogin id="submit">LOGIN</BtLogin>
      {/* <DivFooterButton>
            Não tem uma conta? <EsqueciMinhaSenha>Criar uma conta</EsqueciMinhaSenha>
      </DivFooterButton> */}
      {/*Não tem uma conta? Criar uma conta*/}
    </form>
  );
}


const Login: React.FC = () => {
  return (
    <>
  <ContentBackground>
    <PaiLogin> 
    <StyledImage src={logo} alt="Input Icon" />
      <Cardlogin>
        <PaiInput>
          <Cardinput/>
        </PaiInput>
      </Cardlogin>
    </PaiLogin>
  </ContentBackground>
    

    </>
  )
}

export default Login
