import { ReactElement, createContext, useContext, useEffect, useState } from 'react'
import { noop } from '../utils'

export interface ICasasDeApostasContext {
	casas: any,
	setCasas: (casas: any) => void,
	adicionarModal: boolean,
	casaSendoEditada: any,
	setCasaSendoEditada: (c: {}) => void,
	HandleAdicionarModal: () => void,
	AddCasa: (objCasa: any) => void,
	EditCasaData: (dadosCasa: any) => void,
	EditCasa: any,
	parametrosDelete: any,
	setParametrosDelete: (parametros: any) => void,
	GetCasas: any,
}

export interface ICasasDeApostasContextState {
	children: ReactElement
}

const defaultState: ICasasDeApostasContext = {
	casas: {
		"columns": ["Loading", "Loading", "Loading", "Loading"],
		"data": [
			["Loading", "Loading", "Loading", "Loading"],
		]
	},
	setCasas: noop,
	adicionarModal: false,
	HandleAdicionarModal: noop,
	casaSendoEditada: {},
	setCasaSendoEditada: noop,
	AddCasa: noop,
	EditCasaData: noop,
	EditCasa: noop,
	setParametrosDelete: noop,
	parametrosDelete: null,
	GetCasas: noop,
}

export const CasasDeApostasContext = createContext<ICasasDeApostasContext>(defaultState)
CasasDeApostasContext.displayName = 'CasasDeApostasContext'

export const CasasDeApostasProvider = ({
	children,
}: ICasasDeApostasContextState): JSX.Element => {
	const [casas, setCasas] = useState<{}>(defaultState.casas)
	const [adicionarModal, setAdicionarModal] = useState<boolean>(defaultState.adicionarModal)
	const [casaSendoEditada, setCasaSendoEditada] = useState<any>(defaultState.casaSendoEditada)
	const [parametrosDelete, setParametrosDelete] = useState<any>(defaultState.parametrosDelete)

	const GetCasas = () => {
		return {
			"columns": ["Casa", "Tananan", "City", "State"],
			"data": [
				["Joe James", "Test Corp", "Yonkers", "NY"],
				["John Walsh", "Test Corp", "Hartford", "CT"],
				["Bob Herm", "Test Corp", "Tampa", "FL"],
				["James Houston", "Test Corp", "Dallas", "TX"],
			]
		}

		//axios.get('http://localhost:5000/api/clientes')
	}

	const EditCasaData = (dadosCasas: any) => {
		let objCasa: any = [];
		let editData: any = {};

		//@ts-ignore
		casas.columns.forEach((c, index) => {
			objCasa.push({ [c]: c })
		})
		//@ts-ignore
		casas.columns.forEach((c, index) => {
			editData[c] = dadosCasas[index]
		})

		setCasaSendoEditada({ objCasa, editData })
	}

	const EditCasa = (objCasa: {}) => {
		console.log(objCasa)
	}

	const DeleteCasa = () => {

	}

	const AddCasa = (objCasa: {}) => {
		console.log(objCasa)

		//axios.post('http://localhost:5000/api/clientes', objCliente)
	}

	const CadastrarLinkMesaCustomizado = () => {

	}

	const HandleAdicionarModal = () => {
		setAdicionarModal(!adicionarModal)
	}

	

	return (
		<CasasDeApostasContext.Provider value={{
			casas,
			setCasas,
			adicionarModal,
			casaSendoEditada,
			setCasaSendoEditada,
			HandleAdicionarModal,
			AddCasa,
			EditCasaData,
			EditCasa,
			setParametrosDelete,
			parametrosDelete,
			GetCasas,
		}}>
			{children}
		</CasasDeApostasContext.Provider>
	)
}

export const useCasasDeApostas = (): ICasasDeApostasContext => useContext(CasasDeApostasContext)
