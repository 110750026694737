import CrudTopbar from "../../components/Crud/crud-topbar";
import CrudTabela from "../../components/Crud/crud-tabela";
import Modal from "../../components/Modal";
import { useClientes } from "../../context/clientes.context";
import { Button } from "@mui/material";
import { useEffect } from "react";
import { useAuth } from "../../context/auth.context";

const Clientes = () => {
  const { HandleAdicionarModal, adicionarModal, AddCliente, EditCliente, clientes, EditClienteData, setClienteSendoEditado, clienteSendoEditado, parametrosDelete, setParametrosDelete, GetClientes, setClientes, DeleteCliente } = useClientes();

  useEffect(()=>{
    const verifAuth = () => {
      const verifToken = localStorage.getItem('@Token:SmarttipsAdmin')
      if (verifToken == null || verifToken == '') {
        window.location.href = './login'
      }
    }
    verifAuth()
  }, [])

  useEffect(()=>{
    const clientId = localStorage.getItem('@AppUsuarioId:SmarttipsAdmin')
    GetClientes(clientId)
  })

  return (
    <>
      <CrudTopbar nomeDaPagina="Usuários do App" onClick={() => HandleAdicionarModal()} />
      <CrudTabela
        nome="Lista de Usuários"
        columns={[...clientes.columns, {
          label: "Ações",
          options: {
            customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
              return (
                <>
                  {/* <Button onClick={() => {
                    console.log(value, tableMeta)
                    EditClienteData(tableMeta.rowData)

                  }}>
                    Editar
                  </Button> */}
                  <Button onClick={() => {
                    console.log(value, tableMeta)
                    setParametrosDelete({ id: tableMeta.rowData[0] })
                  }}>
                    Remover
                  </Button>
                </>
              )
            }
          }
        }]}
        data={clientes.data} 
        />
      <Modal
        parametrosOcultos={[{ ClientId: localStorage.getItem('@ClientId:SmarttipsAdmin'), SubDominio: localStorage.getItem('@Subdominio:SmarttipsAdmin')}]}
        campos={[{ Email: "Email", Nome: "Nome", Telefone: "Telefone", Senha: "Senha",  }]}
        nome="Adicionar Cliente"
        open={adicionarModal}
        onClose={() => HandleAdicionarModal()}
        Metodo={AddCliente}
      />

      {
        clienteSendoEditado.editData !== undefined && <Modal
          campos={clienteSendoEditado.objCliente}
          EditData={clienteSendoEditado.editData}
          nome="Editar Cliente"
          open={clienteSendoEditado.editData !== undefined}
          onClose={() => setClienteSendoEditado({})}
          Metodo={EditCliente}
        />
      }
      
      {
        parametrosDelete !== null && <Modal
          Delete
          nome="Deletar Cliente"
          open={parametrosDelete !== null}
          onClose={() => setParametrosDelete(null)}
          Metodo={DeleteCliente}
        />
      }
    </>
  )
}

export default Clientes;
