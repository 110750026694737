import styled from 'styled-components'

export const Background = styled.div`
  width: 100%;
  height: 110vh;
  display: flex;
  background: radial-gradient(
    119.77% 119.77% at 50% -17.6%,
    #172955 11.98%,
    rgba(21, 21, 32, 100) 39.58%
  );
`
export const ContainerBody = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 100px;
`
