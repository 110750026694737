import CrudTopbar from "../../components/Crud/crud-topbar";
import CrudTabela from "../../components/Crud/crud-tabela";
import Modal from "../../components/Modal";
import { Button } from "@mui/material";
import { useEffect } from "react";
import { useAuth } from "../../context/auth.context";
import { useCriarCliente } from "../../context/criarCliente.context";

const CriarWebApp = () => {
  const { HandleAdicionarModal, HandleModalCadUsuario, HandleModalWhiteLabel, HandleModalGrupoBetSinais, adicionarModal, clientes, parametrosDelete, modalCadUsuario, modalGrupoBetSinais, modalWhiteLabel, setParametrosDelete, GetClientes, setClientes, CadUsuario, WhiteLabel, GrupoBetSinais, HandleGoToSite} = useCriarCliente();

  useEffect(()=>{
    const verifAuth = () => {
      const verifToken = localStorage.getItem('@Token:SmarttipsAdmin')
      if (verifToken == null || verifToken == '') {
        window.location.href = './login'
      }
    }
    verifAuth()
  }, [])

  useEffect(() => {
	  //GetClientes()
	}, [])

  return (
    <>
    
      <CrudTopbar nomeDaPagina="Criar Site - Parte 1" textoButton="Criar" onClick={() => HandleModalCadUsuario()} />
      <CrudTopbar nomeDaPagina="Configurar Grupo - Parte 2" textoButton="Configurar Grupo" onClick={() => HandleModalWhiteLabel()} />
      <CrudTopbar nomeDaPagina="Configurar Jogos - Parte 3" textoButton="Configurar Jogos" onClick={() => GrupoBetSinais(localStorage.getItem('@ClientId:SmarttipsAdmin'))} />
      <CrudTopbar nomeDaPagina="Acessar seu site" textoButton="Acessar" onClick={() => HandleGoToSite(localStorage.getItem('@ClientId:SmarttipsAdmin'))} />
  
      
      <Modal
        parametrosOcultos={[{ clientId: localStorage.getItem('@ClientId:SmarttipsAdmin')}]}
        campos={[{ SubDominio: "Subdominio"}]}
        nome="Criar Site"
        open={modalCadUsuario}
        onClose={() => HandleModalCadUsuario()}
        Metodo={CadUsuario}
      /> 

    <Modal
        parametrosOcultos={[{ clientId: localStorage.getItem('@ClientId:SmarttipsAdmin')}]}
        campos={[{ tituloPage: "Titulo da página", linkAfiliado: "Link de Afiliado" }]}
        nome="Configurar Grupo"
        open={modalWhiteLabel}
        onClose={() => HandleModalWhiteLabel()}
        Metodo={WhiteLabel}
      /> 

    <Modal
        parametrosOcultos={[{ clientId: localStorage.getItem('@ClientId:SmarttipsAdmin')}]}
        campos={[{  }]}
        nome="Configurar Grupo"
        open={modalGrupoBetSinais}
        onClose={() => HandleModalGrupoBetSinais()}
        Metodo={GrupoBetSinais}
      /> 
     
    </>
  )
}

export default CriarWebApp;
