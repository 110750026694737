import React, { useState, useEffect } from 'react'

import { ContainerMonitor } from './styles'

import { GetTodosGrupos } from '../../services/Roleta'
import CardGrupo from '../../components/CardGrupo'





const MonitorGrupos: React.FC = () => {
    const [grupos, setGrupos] = useState([])

    useEffect(()=>{
        const fetchData = async () => {
            const response = await GetTodosGrupos(localStorage.getItem('@ClientId:SmarttipsAdmin'))
            setGrupos(response)
        }

        fetchData()
        
    }, [])
    
    //const reformattedArray = kvArray.map(({ key, value }) => ({ [key]: value }));
    return (
        <>
            <ContainerMonitor>
                <CardGrupo/>
            </ContainerMonitor>
            
        </>
    )
}

export default MonitorGrupos
