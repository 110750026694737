import styled from 'styled-components'

export const ContainerTopbar = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  padding: 16px;
  box-sizing: border-box;
  @media screen and (max-width: 900px) {
    justify-content: flex-end;
  }
`
export const TextActualPage = styled.h1`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 150%;
  color: #ffffff;
  margin: 0;
`
