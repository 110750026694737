import { flexbox } from '@mui/system'
import styled from 'styled-components'


export const PaiLogin = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 0px;
`
export const Cardlogin = styled.div`
  width: 584px;
  height: 450px;
  background: #21222d;
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.35);
  border-radius: 12px;
  

  @media screen and (max-width: 769px){
    background: none;
    box-shadow: none;
    width: 570px;

  }
`

export const ContentBackground = styled.div`
width: 100vw;
height: 100vh;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;

// border: 1px solid;
background:radial-gradient( 119.77% 119.77% at 50% -17.6%, #172955 11.98%, rgba(21,21,32,100) 39.58% );
`
export const PaiInput = styled.div`
display:grid
justify-content: center;
flex-direction: row;
margin:50px;

// margin-bottom: 100px;
// padding:10px

`


export const H2 = styled.h2`
  font-family: 'Montserrat', sans-serif;
  font-style: italic;
  font-weight: bold;
  font-size: 30px;
  line-height: 150%;
  /* identical to box height, or 48px */
 

  color: #FFFFFF;

  @media screen and (min-width: 770px){
    margin-top: -20px;
  }
`;

export const P = styled.p`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  /* or 24px */

  margin-bottom: -10px;
  color: #9A9AA4;

`;



export const StyledInput = styled.input`
width: 480px;
height: 50px;
border: 1px solid #9A9AA4;
background-color: rgb(0, 0, 0, 0);
border-radius: 4px;
margin-top: 15px;
position: relative;

&::placeholder{
  color: #9A9AA4;
  opacity: 1;
  font-weight: 400;
  font-family: 'Montserrat';
  align-self: center;
  padding-left: 10px;
}
&:not(:placeholder-shown) {
  color: white;
  opacity: 1;
  font-weight: 600;
  font-family: 'Montserrat';
  align-self: center;
  padding-left: 10px;
  }

  &:focus{
    outline: none;
  }
`

export const InputsDiv = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: center;
  justify-content: center;
`;

export const StyledImage = styled.img`
/* position: absolute;
left: 46.50%;
right: 50.56%;
top: 5%;
bottom: 45.33%; */
@media screen and (min-width: 770px){
  margin-bottom: 50px;
}
`;


export const BtLogin = styled.button`
  background: #E2C03C;
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.35);
  border-radius: 4px;
  width: 100%;
  height: 52px;
  color: white;
  font-weight: 600;
  margin-top: 30px;
  border: none;



  &:hover{
    cursor: pointer;
    background: #b89d31;

  }
`;

export const CheckBox = styled.input.attrs({type: 'checkbox'})`
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  border: 1px solid #6D6D6D;
  border-radius: 4px;
  outline: none;
  cursor: pointer;

  &:checked {
    content: 'V'
    background-color: "#2F2F39";
    color: red;
    &::before {
      content: '';
      position: absolute;
    
      transform: translate(-50%, -50%);
      width: 8px;
      height: 16px;
      border: solid #ffce00;
      border-width: 0 2px 2px 0;
      /* rotação de 45 graus para formar o sinal de check */
      transform: rotate(45deg);
      margin-left: 4px;
      margin-top: -4px;
    }
  }

`;
export const CheckBoxLabel = styled.label`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
/* identical to box height, or 21px */
  color: #FFFFFF;
`;

export const DivCheckBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

`

export const EsqueciMinhaSenha = styled.span`
  font-family: 'Montserrat';
font-style: normal;
font-weight: bold;
font-size: 14px;
line-height: 150%;
/* identical to box height, or 21px */

text-align: right;

/* Yellow/Yellow 1 */

color: #E2C03C;
`;

export const DivFooterInputs = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
`;

export const DivFooterButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  gap: 10px;
  color: white;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
`;