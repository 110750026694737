import axios from 'axios'
axios.defaults.headers.get['Content-Type'] ='application/json;charset=utf-8';
axios.defaults.headers.get['Access-Control-Allow-Origin'] = '*';


export const PostLogin = async (usuario: { nome: any, email: any, senha: any }) => {
    let url = `https://admin.linerasistemas.com.br/api/Acesso/Login`
  
  
    const response = await axios.post(url, usuario, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
    const token = response.data.token;
    sessionStorage.setItem('token', token)
    // console.log(token)
    
    window.location.href = `/`

    
    return response;
  
  }

  export const GetPermissao = async (userId: any) => {
    let url = `https://admin.linerasistemas.com.br/api/Acesso/GetPermissoes?UsuarioId=${userId}`;
  
    try{
      const response = await axios.get(url)
  
      return response.data;
  
    } catch (error) {
      console.log(error)
    }
  }

