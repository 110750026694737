import React, { useEffect, useState, createContext } from 'react'
import CircleRoundedIcon from '@mui/icons-material/CircleRounded'
import { faker } from '@faker-js/faker/locale/pt_BR'
import {
  CardMonitor,
  CardStatusA,
  CardStatusB,
  ContainerIMG,
  ContainerMonitor,
  TextoAtt,
  TextoNome,
  TextStatus,
  TextStatusB,
} from './styles'
import { GetTodosGrupos, GetTodosJogosMonitor } from '../../services/Roleta'
import { Navigate, useNavigate } from 'react-router-dom'



const CardGrupo: React.FC = () => {
  const [grupos, setGrupos] = useState([]);
  const navigate = useNavigate()

 
  useEffect(() => {
    

    const fetchMonitorGrupos = async () => {
      const data = await GetTodosGrupos(localStorage.getItem('@tblChat_ClientId:SmarttipsAdmin'));
      setGrupos(data);
      console.log(data)
    }
    

    fetchMonitorGrupos();
  }, [])

    

  return ( 
    <>
      <ContainerMonitor>
        {grupos.map(grupo => (
          
          <CardMonitor onClick={()=>{
            navigate(`/monitor/${grupo[3]}`)
          }}>
            <div style={{width: '10px', height: '10px', borderRadius: '50px', 
            background: grupo[2] ? '#41B878' : '#ef2f3c', 
            position: 'absolute', right: '10px', top: '10px'}}></div>
          <ContainerIMG>
              <img src={grupo[2]} style={{objectFit: 'contain', width: '60px', height: '60px', borderRadius: '50px'}} />
            </ContainerIMG>
            <TextoNome>{grupo[1]}</TextoNome>
            <TextoAtt>Ultima Atualização: {grupo[0]}</TextoAtt>
            

          </CardMonitor>
     
        ))}
      </ContainerMonitor>
    </>
  )
}

export default CardGrupo
