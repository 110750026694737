import React from 'react'
import { ContainerTopbar, TextActualPage } from './styles'
import Userbar from '../userbar'

const TopBar: React.FC = () => {
  return (
    <ContainerTopbar>
      <Userbar />
    </ContainerTopbar>
  )
}

export default TopBar
