import styled from 'styled-components'

export const ContainerUserBar = styled.div`
  display: flex;
  align-items: center;
  gap: 22px;
  justify-content: flex-end;
`
export const ContainerProfile = styled.div`
  background: #ffffff50;
  height: 100%;
  border-radius: 4px;
  padding: 8px;
  margin-top: 8px;
`
