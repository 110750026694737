import { ReactElement, createContext, useContext, useEffect, useState } from 'react'
import { noop } from '../utils'
import axios from 'axios';
import { GetPermissao, PostLogin } from '../services/auth';
import { LocalHospitalTwoTone } from '@mui/icons-material';
import { GetSubdominio } from '../services/criarCliente';

export interface IAuthContext {
	login: (usuario: any) => void
  verifAuth: () => void
  logout: () => void
  perm: (id: any) => void;
  permissoes: any;
  setPermissoes: any;
  pegarSubdomain: (id: any) => void;
}

export interface IAuthContextState {
	children: ReactElement
}

const defaultState: IAuthContext = {
	login: noop,
  logout: noop,
  verifAuth: noop,
  perm: noop,
  permissoes: null,
  setPermissoes: noop,
  pegarSubdomain: noop,
}

export const AuthContext = createContext<IAuthContext>(defaultState)
AuthContext.displayName = 'AuthContext'

export const AuthProvider = ({
	children,
}: IAuthContextState): JSX.Element => {
	const [permissoes, setPermissoes] = useState(defaultState.permissoes)

  const pegarSubdomain = async (clientId: any) => {
    const response = await GetSubdominio(clientId)
    .then((subdomain)=>{
      localStorage.setItem('@Subdominio:SmarttipsAdmin', subdomain.data[0])
    })
  }
	
const login = async (usuario: any) => {
  const response = await PostLogin(usuario)

  localStorage.setItem('@ClientId:SmarttipsAdmin', response.data["clientId"])
  localStorage.setItem('@Token:SmarttipsAdmin', response.data["token"])
  localStorage.setItem('@UserEmail:SmarttipsAdmin', usuario.email)
  localStorage.setItem('@UserNome:SmarttipsAdmin', usuario.nome)
  localStorage.setItem('@UserId:SmarttipsAdmin', response.data["userId"])
  localStorage.setItem('@AppUsuarioId:SmarttipsAdmin', response.data["appUsuarioId"])
  localStorage.setItem('@tblChat_ClientId:SmarttipsAdmin', response.data["tblChat_ClientId"])

  if(response.data['clientId'] == '1000'){
    localStorage.setItem('@Responsavel:SmarttipsAdmin', "OTG")
  }

  

  return response
}

const logout = () => {
  localStorage.clear()
}

const verifAuth = () => {
  // const verifToken = sessionStorage.getItem('@Token:SmarttipsAdmin')
  // if (verifToken == null) {
  //   window.location.href = './'
  // }
}

const perm = async () => {
  const response = await GetPermissao(localStorage.getItem('@UserId:SmarttipsAdmin'))
  setPermissoes(response)
}

	return (
		<AuthContext.Provider value={{
			login,
      logout,
      verifAuth,
      perm,
      permissoes,
      setPermissoes,
      pegarSubdomain
		}}>
			{children}
		</AuthContext.Provider>
	)
}

export const useAuth = (): IAuthContext => useContext(AuthContext)
