import * as React from 'react'
import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import { Button, Collapse, Drawer, ListSubheader } from '@mui/material'
import logo from '../../assets/logo.png'

import AppRegistrationOutlinedIcon from '@mui/icons-material/AppRegistrationOutlined'
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import SettingsApplicationsOutlinedIcon from '@mui/icons-material/SettingsApplicationsOutlined'
import { ContainerSidebarDesktop, ContainerSidebarMobile } from './styles'
import MenuLateral from './menu'
import MenuIcon from '@mui/icons-material/Menu'

const SideBar: React.FC = () => {
  const [open, setOpen] = React.useState(false)
  const [drawerOpen, setDrawerOpen] = React.useState(false)

  const handleClick = (): void => {
    setOpen(!open)
  }

  return (
    <>
      <ContainerSidebarMobile>
        <Button onClick={() => setDrawerOpen(true)}>
          <MenuIcon />
        </Button>
      </ContainerSidebarMobile>
      <ContainerSidebarDesktop>
        <MenuLateral />
      </ContainerSidebarDesktop>
      <Drawer
        anchor="left"
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
      >
        <MenuLateral />
      </Drawer>
    </>
  )
}

export default SideBar
