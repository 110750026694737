import axios from 'axios'
axios.defaults.headers.get['Content-Type'] ='application/json;charset=utf-8';
axios.defaults.headers.get['Access-Control-Allow-Origin'] = '*';

export const GetJogosModelo = async () => {
    try{
        const response = await axios.get(`https://admin.linerasistemas.com.br/api/NovoCliente/GetJogosModelos`)

        return response.data;

    } catch (error) {
        console.log(error)
    }
}

export const InsertReplicador = async (cliente: { grupoOrigemMensagem: any, grupoRepasseMensagem: any, nomeGrupo: any }) => {
    let url = `https://admin.linerasistemas.com.br/api/NovoCliente/InsertReplicador`
  
  
    const response = await axios.post(url, cliente, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
    const token = response.data.token;
    sessionStorage.setItem('token', token)
    // console.log(token)
    
    return response;
  
}

export const InsertCadUsuario = async (usuario: { ClientId: any, SubDominio: any}) => {
  let url = `https://admin.linerasistemas.com.br/api/NovoCliente/InsertCadUsuario`
  
  
    const response = await axios.post(url, usuario, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
    const token = response.data.token;
    sessionStorage.setItem('token', token)
    // console.log(token)
    
    return response;
}

export const InsertWhiteLabel = async (usuario: { ClientId: any, TituloPage: any, LinkAfiliado: any}) => {
  let url = `https://admin.linerasistemas.com.br/api/NovoCliente/InsertWhiteLabel`
  
  
    const response = await axios.post(url, usuario, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
    const token = response.data.token;
    sessionStorage.setItem('token', token)
    // console.log(token)
    
    return response;
}

export const InsertGrupoBetSinais = async (usuario: { ClientId: any}) => {
  let url = `https://admin.linerasistemas.com.br/api/NovoCliente/InsertGrupoBetSinais`
  
  
    const response = await axios.post(url, usuario, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
    const token = response.data.token;
    sessionStorage.setItem('token', token)
    // console.log(token)
    
    return response;
}



export const GetSubdominio = async (clientId: any) => {
  try{
      const response = await axios.get(`https://admin.linerasistemas.com.br/api/NovoCliente/GetSubdomain?ClientId=${clientId}`)

      return response.data;

  } catch (error) {
      console.log(error)
  }
}



export const GetListaClientes = async (clientId: any) => {
  try{
      const response = await axios.get(`https://admin.linerasistemas.com.br/api/NovoCliente/GetListaClientes?ClientId=${clientId}`)

      return response.data;

  } catch (error) {
      console.log(error)
  }
}

export const DeleteUsuario = async (id: any) => {
  let url = `https://admin.linerasistemas.com.br/api/NovoCliente/DeleteUsuario/${id}`


  const response = await axios.delete(url, {
    headers: {
      'Content-Type': 'application/json'
    }
  })
  
  return response;
}

//api/NovoCliente/InsertUsuarioApp?Email=woifwn%40fno&Nome=nfwiowfin&Senha=1234567&SubDominio=nwionw&Telefone=280294

export const InsertUsuarioApp = async (Email: any, Nome: any, Senha: any, SubDominio: any, Telefone: any) => {
  let url = `https://admin.linerasistemas.com.br/api/NovoCliente/InsertUsuarioAdpp?Email=${Email}&Nome=${Nome}&Senha=${Senha}&SubDominio=${SubDominio}&Telefone=${Telefone}`
  
  
    const response = await axios.post(url, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
    
 
    
    return response;
}