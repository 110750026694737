import { ReactElement, createContext, useContext, useEffect, useState } from 'react'
import { noop } from '../utils'
import { DeletarPergunta, GetListaPerguntas, InsertPergunta, UpdatePergunta } from '../services/perguntas';

export interface IPerguntasContext {
	perguntas: any,
	setPerguntas: (perguntas: any) => void,
	adicionarModal: boolean,
	perguntaSendoEditado: any,
	setPerguntaSendoEditado: (c: {}) => void,
	HandleAdicionarModal: () => void,
	DeletePergunta: any
	AddPergunta: (objPergunta: any) => void,
	EditPerguntaData: (dadosCleiente: any) => void,
	EditPergunta: any,
	parametrosDelete: any,
	setParametrosDelete: (parametros: any) => void,
	GetPerguntas: (clientid: any) => void;
}

export interface IPerguntasContextState {
	children: ReactElement
}

const defaultState: IPerguntasContext = {
	perguntas: {
		"columns": ["Loading", "Loading", "Loading", "Loading"],
		"data": [
			["Loading", "Loading", "Loading", "Loading"],
		]
	},
	setPerguntas: noop,
	adicionarModal: false,
	HandleAdicionarModal: noop,
	perguntaSendoEditado: {},
	setPerguntaSendoEditado: noop,
	AddPergunta: noop,
	EditPerguntaData: noop,
	EditPergunta: noop,
	setParametrosDelete: noop,
	parametrosDelete: null,
	GetPerguntas: noop,
	DeletePergunta: noop,
	
}

export const PerguntasContext = createContext<IPerguntasContext>(defaultState)
PerguntasContext.displayName = 'PerguntasContext'

export const PerguntasProvider = ({
	children,
}: IPerguntasContextState): JSX.Element => {
	const [perguntas, setPerguntas] = useState<{}>(defaultState.perguntas)
	const [adicionarModal, setAdicionarModal] = useState<boolean>(defaultState.adicionarModal)
	const [perguntaSendoEditado, setPerguntaSendoEditado] = useState<any>(defaultState.perguntaSendoEditado)
	const [parametrosDelete, setParametrosDelete] = useState<any>(defaultState.parametrosDelete)

	const GetPerguntas = async (ClientId: any) => {
		const response = await GetListaPerguntas(ClientId)
		setPerguntas(response)
		return response
		//axios.get('http://localhost:5000/api/perguntas')
	}

	const EditPerguntaData = (dadosPerguntas: any) => {
		let objPergunta: any = [];
		let editData: any = {};

		//@ts-ignore
		perguntas.columns.forEach((c, index) => {
			objPergunta.push({ [c]: c })
		})
		//@ts-ignore
		perguntas.columns.forEach((c, index) => {
			editData[c] = dadosPerguntas[index]
		})

		setPerguntaSendoEditado({ objPergunta, editData })
	}

	const EditPergunta = async (objPergunta: any) => {
		console.log(objPergunta)
		const response = await UpdatePergunta(objPergunta["Pergunta"], objPergunta["Posição"], objPergunta["Id"])
		.then((response)=>{
			alert('Pergunta atualizada.')
			setPerguntaSendoEditado({})
		}).catch((error) => {
			alert(error)
		})

		return response
	}

	const DeletePergunta = async (id: any) => {
		console.log(parametrosDelete["id"], 'id')
		const response = await DeletarPergunta(parametrosDelete["id"])
		.then(()=>{
			alert("Pergunta Removida")
			setParametrosDelete(null)
		}).catch((err) => {
			alert(err)
		})

		return response
		
	}

	const AddPergunta = async (objPergunta: any) => {
		console.log(objPergunta)
		const response = await InsertPergunta(objPergunta["IndexState"], objPergunta["TextoPergunta"], objPergunta["ClientId"])
		.then((response) => {
			alert("Pergunta adicionada")
			setAdicionarModal(!adicionarModal)
		}).catch((error)=>{
			alert(error)
		})

		return response
		//axios.post('http://localhost:5000/api/perguntas', objPergunta)
	}

	const HandleAdicionarModal = () => {
		setAdicionarModal(!adicionarModal)
	}


	return (
		<PerguntasContext.Provider value={{
			perguntas,
			setPerguntas,
			adicionarModal,
			perguntaSendoEditado,
			setPerguntaSendoEditado,
			HandleAdicionarModal,
			AddPergunta,
			EditPerguntaData,
			EditPergunta,
			setParametrosDelete,
			parametrosDelete,
			GetPerguntas,
			DeletePergunta
			
		}}>
			{children}
		</PerguntasContext.Provider>
	)
}

export const usePerguntas = (): IPerguntasContext => useContext(PerguntasContext)
