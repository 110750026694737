import React, { useState } from 'react'
import { ContentBackground,PaiLogin,Cardlogin, StyledImage, PaiInput,  StyledInput, InputsDiv, BtLogin, CheckBox, CheckBoxLabel, DivCheckBox, EsqueciMinhaSenha, DivFooterInputs, DivFooterButton, H2, P} from './styles'
import logo from '.././../assets/LOGO2.png'
import PersonIcon from '@mui/icons-material/Person';
import LockIcon from '@mui/icons-material/Lock';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

function Cardinput() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  function handleUsernameChange(e: { target: { value: React.SetStateAction<string>; }; }) {
    setUsername(e.target.value);
  }

  function handlePasswordChange(e: { target: { value: React.SetStateAction<string>; }; }) {
    setPassword(e.target.value);
  }
  
  return (
    <form>

      <H2>Esqueceu a sua senha?</H2>
      <P>Enviaremos um e-mail com as instruções de como redefinir sua senha.</P>
      <InputsDiv>

      <StyledInput 
        type="text"
        placeholder="E-mail"
        value={username}
        onChange={handleUsernameChange}
      />
      </InputsDiv>

     
 
      <BtLogin id="submit" type="submit">ENVIAR</BtLogin>
      <DivFooterButton>
            <EsqueciMinhaSenha>Voltar para o login</EsqueciMinhaSenha>
      </DivFooterButton>
     
    </form>
  );
}


const CardRecuperarSenha: React.FC = () => {
  return (
    <>
  <ContentBackground>
    <PaiLogin> 
    <StyledImage src={logo} alt="Input Icon" />
      <Cardlogin>
        <PaiInput>
          <Cardinput/>
        </PaiInput>
      </Cardlogin>
    </PaiLogin>
  </ContentBackground>
    

    </>
  )
}

export default CardRecuperarSenha
