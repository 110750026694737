import React from "react";
import MUIDataTable, { MUIDataTableOptions } from "mui-datatables";
import { Container, Table } from "./styles";

interface IProps {
    columns: any[];
    data: any[];
    nome: string;
}

export const CrudTabela: React.FC<IProps> = ({columns, data, nome}) => {
    //.......
    const options: MUIDataTableOptions = {
        filterType: 'dropdown',
        selectableRows: undefined,
        filter: false,
        viewColumns: false,
        responsive: 'simple',
        textLabels: {
            body: {
                noMatch: "Nenhum dado encontrado na tabela.",
            },
        },
    };
  
    return (
        <div style={{overflow: 'scroll'}}>
            <Container>
                <Table
                    title={nome}
                    data={data}
                    columns={columns}
                    options={options}
                />
            </Container>
        </div>
    )
}

export default CrudTabela;