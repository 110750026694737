import { ReactElement, createContext, useContext, useEffect, useState } from 'react'
import { noop } from '../utils'
import { GetJogosModelo, InsertReplicador } from '../services/criarCliente';
import { InsertBotConfig, InsertBotGrupo, GetBotPorCliente, UpdateBotConfig, GetLinksTelegram, UpdateBotLink, DeleteBotConfig } from '../services/leads';


export interface ICriarBotContext {
	bots: any,
	setBots: (bots: any) => void,
	adicionarModal: boolean,
	adicionarModal2: boolean,
	botSendoEditado: any,
	setBotSendoEditado: (c: {}) => void,
	HandleAdicionarModal: () => void,
	HandleAdicionarModal2: () => void,
	CriarBot: (objBot: any) => void,
	parametrosDelete: any,
	setParametrosDelete: (parametros: any) => void,
	GetBots: () => void;
	ConfigurarGrupo: (objBot: any) => void,
	EditBotData: (objBot: any) => void,
	EditBot: any,
	linkSendoEditado: any,
	setLinkSendoEditado: (links: {}) => void,
	EditLinks: any,
	EditLinksData: (telegram: any) => void,
	GetTelegram: () => void,
	telegram: any
	setTelegram: (parametros: any) => void
	CriarLink: (objLink: any) => void
	DeleteBot: any
}


export interface ICriarBotContextState {
	children: ReactElement
}

const defaultState: ICriarBotContext = {
	bots: {
		"columns": ["Loading", "Loading", "Loading", "Loading"],
		"data": [
			["Loading", "Loading", "Loading", "Loading"],
		]
	},
	setBots: noop,
	adicionarModal: false,
	adicionarModal2: false,
	HandleAdicionarModal: noop,
	HandleAdicionarModal2: noop,
	botSendoEditado: {},
	setBotSendoEditado: noop,
	CriarBot: noop,
	setParametrosDelete: noop,
	parametrosDelete: null,
	GetBots: noop,
	ConfigurarGrupo: noop,
	EditBotData: noop,
	EditBot: noop,
	linkSendoEditado: {},
	setLinkSendoEditado: noop,
	EditLinks: noop,
	EditLinksData: noop,
	GetTelegram: noop,
	telegram: {},
	setTelegram: noop,
	CriarLink: noop,
	DeleteBot: noop,
}

export const CriarBotContext = createContext<ICriarBotContext>(defaultState)
CriarBotContext.displayName = 'CriarBotContext'

export const CriarBotProvider = ({
	children,
}: ICriarBotContextState): JSX.Element => {
	const [bots, setBots] = useState<{}>(defaultState.bots)
	const [adicionarModal, setAdicionarModal] = useState<boolean>(defaultState.adicionarModal)
	const [adicionarModal2, setAdicionarModal2] = useState<boolean>(defaultState.adicionarModal2)
	const [botSendoEditado, setBotSendoEditado] = useState<any>(defaultState.botSendoEditado)
	const [parametrosDelete, setParametrosDelete] = useState<any>(defaultState.parametrosDelete)
	const [linkSendoEditado, setLinkSendoEditado] = useState<any>(defaultState.linkSendoEditado)
	const [telegram, setTelegram] = useState<any>(defaultState.telegram)

	const GetBots = async () => {
		const response = await GetBotPorCliente(localStorage.getItem('@ClientId:SmarttipsAdmin'))
		setBots(response)
	}
	

	const EditBotData = (dadosBot: any) => {
		let objBot: any = [];
		let editData: any = {};


		//@ts-ignore
		bots.columns.forEach((c, index) => {
			objBot.push({ [c]: c })
		})
		//@ts-ignore
		bots.columns.forEach((c, index) => {
			editData[c] = dadosBot[index]
		})
	
        

		setBotSendoEditado({ objBot, editData })
		
	}

	const EditBot = async (objBot: any) => {
		console.log(objBot)

		const response = await UpdateBotConfig(localStorage.getItem('@ClientId:SmarttipsAdmin'), objBot.TextoAcao, objBot.Bot, objBot.NomeBot, objBot.TextoBoasVindas, objBot.Id);
		return response
	}

	const GetTelegram = async () => {
		const response = await GetLinksTelegram(localStorage.getItem('@ClientId:SmarttipsAdmin'))
		setTelegram(response)
	}

	const EditLinksData = async (objTelegram: any) => {
		let objLinks: any = [];
		let editData: any = {};
		
		
		//@ts-ignore
		telegram.data.forEach((c, index) => {
			objLinks.push(c)
		})

		console.log(objLinks)
	
		
		//@ts-ignore
		telegram.data.forEach((c, index) => {

			const tamanho = objLinks.length * objLinks[index].length
			
			for(let j = 0; j < objLinks.length; j++){
				// console.log(objLinks[j], 'objLinks J')
				for(let i = 0; i < tamanho; i++){
					editData[i] = objLinks[j][i]
					console.log(objLinks[j][i])
				}
			}
			
			
		})
		
        
		console.log(editData)

		setLinkSendoEditado({ objLinks, editData })
		
	}

	
	const EditLinks = async (editData: any) => {
		console.log(linkSendoEditado, ": edit")
		

		for(let i = 0; i < telegram.data.length; i++){
			
			// await UpdateBotLink(
			// 	telegram.data[i][0], 
			// 	telegram.data[i][1], 
			// 	localStorage.getItem('@ClientId:SmarttipsAdmin'), 
			// 	telegram.data[i][2])
			// 	.then(()=>{
			// 		console.log('grupo alterado: ', telegram.data[i])
			// })
		}

		//alert("Grupos atualizados")
		
	}

	const DeleteBot = async () => {
		
		const response = await DeleteBotConfig(parametrosDelete.id)
		.then((response)=>{
			alert('Grupo removido com sucesso!')
			setParametrosDelete(null)
		}).catch((err)=>{
			alert(err)
		})
		
		return response
	}

	const CriarBot = async (objBot: any) => {
        //cliente completo
		
		const objBotCerto = {
			clientId: 12,
			textoAcao: objBot.textoAcao,
			afiliadoId: parseInt(objBot.afiliadoId),
			nomeBot: objBot.nomeBot
		}

		console.log(objBotCerto)

		const response = await InsertBotConfig(objBotCerto).then(()=>{
			alert("Bot Configurado")
		}).catch((error)=> {
			alert(error)
		})

		return response

		//axios.post('http://localhost:5000/api/bots', objCliente)
	}

	const CriarLink = async (objLink: any) => {
		console.log(objLink)

		const objCerto = {
			clientId: objLink.clientId,
			textoGrupo: objLink.textoGrupo,
			linkGrupo: objLink.linkGrupo,
			afiliadoId: objLink.afiliadoId,
		}
		const response = await InsertBotGrupo(objLink).then(()=>{
			alert('Bot adicionado!')
		}).catch((err)=>{
			alert(err)
		});
		return response
	}

	const ConfigurarGrupo = async (objBot: any) => {
		
	}

	const HandleAdicionarModal = () => {
		setAdicionarModal(!adicionarModal)
	}

	const HandleAdicionarModal2 = () => {
		setAdicionarModal2(!adicionarModal2)
	}

	

	return (
		<CriarBotContext.Provider value={{
			bots,
			setBots,
			adicionarModal,
			adicionarModal2,
			botSendoEditado,
			setBotSendoEditado,
			HandleAdicionarModal,
			HandleAdicionarModal2,
			CriarBot,
			EditBotData,
			EditBot,
			setParametrosDelete,
			parametrosDelete,
			GetBots,
			ConfigurarGrupo,
			linkSendoEditado,
			setLinkSendoEditado,
			EditLinks,
			EditLinksData,
			GetTelegram,
			telegram,
			setTelegram,
			CriarLink,
			DeleteBot
			
		}}>
			{children}
		</CriarBotContext.Provider>
	)
}

export const useCriarBot = (): ICriarBotContext => useContext(CriarBotContext)
