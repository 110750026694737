import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  ReactElement,
  useMemo,
} from 'react'
import axios, { AxiosInstance } from 'axios'
import { noop } from '../utils'

export interface FetchContextState {
  token: string | null
  setToken: (accessToken: string) => void
  imageApi?: AxiosInstance
  get: <T = any, R = T>(url: string) => Promise<R>
  post: <T = any, R = T, D = any>(url: string, data?: D) => Promise<R>
}

export interface FetchProviderProps {
  children: ReactElement
}

const defaultState: FetchContextState = {
  token: null,
  setToken: noop,
  imageApi: undefined,
  get: noop,
  post: noop,
}

export const FetchContext = createContext<FetchContextState>(defaultState)

export const FetchProvider = ({
  children,
}: FetchProviderProps): JSX.Element => {
  const [token, setToken] = useState<string>(
    localStorage.getItem('access_token') ?? '',
  )

  const api = useMemo(
    () =>
      axios.create({
        baseURL: '/api',
        headers:
          token !== ''
            ? {
                Authorization: `Bearer ${token}`,
              }
            : {},
      }),
    [token],
  )

  const imageApi = useMemo(() => axios.create(), [])

  const get: FetchContextState['get'] = async (url: string) =>
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    (await api.get(url)).data
  const post: FetchContextState['post'] = async (url: string, data) =>
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    (await api.post(url, data)).data

  useEffect(() => {
    localStorage.setItem('access_token', token)
  }, [token])

  return (
    <FetchContext.Provider
      value={{
        token,
        setToken,
        imageApi,
        get,
        post,
      }}
    >
      {children}
    </FetchContext.Provider>
  )
}

export const useFetch = (): FetchContextState =>
  useContext<FetchContextState>(FetchContext)
