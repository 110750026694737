import { ReactElement, createContext, useContext, useEffect, useState } from 'react'
import { noop } from '../utils'
import { GetJogosModelo, GetSubdominio, InsertCadUsuario, InsertGrupoBetSinais, InsertReplicador, InsertWhiteLabel } from '../services/criarCliente';

export interface ICriarClienteContext {
	clientes: any,
	setClientes: (clientes: any) => void,
	adicionarModal: boolean,
	clienteSendoEditado: any,
	setClienteSendoEditado: (c: {}) => void,
	HandleAdicionarModal: () => void,
	HandleModalCadUsuario: () => void,
	modalCadUsuario: boolean,
	HandleModalWhiteLabel: () => void,
	modalWhiteLabel: boolean,
	HandleModalGrupoBetSinais: () => void,
	modalGrupoBetSinais: boolean,
	AddCliente: (objCliente: any) => void,
	EditClienteData: (dadosCleiente: any) => void,
	EditCliente: any,
	parametrosDelete: any,
	setParametrosDelete: (parametros: any) => void,
	GetClientes: () => void;
	CadUsuario: (objUsuario: any) => void;
	WhiteLabel: (objUsuario: any) => void;
	GrupoBetSinais: (objUsuario: any) => void;
	HandleGoToSite: (clientId: any) => void;
}

export interface ICriarClienteContextState {
	children: ReactElement
}

const defaultState: ICriarClienteContext = {
	clientes: {
		"columns": ["Loading", "Loading", "Loading", "Loading"],
		"data": [
			["Loading", "Loading", "Loading", "Loading"],
		]
	},
	setClientes: noop,
	adicionarModal: false,
	HandleAdicionarModal: noop,
	clienteSendoEditado: {},
	setClienteSendoEditado: noop,
	AddCliente: noop,
	EditClienteData: noop,
	EditCliente: noop,
	setParametrosDelete: noop,
	parametrosDelete: null,
	GetClientes: noop,
	HandleModalCadUsuario: noop,
	HandleModalWhiteLabel: noop,
	HandleModalGrupoBetSinais: noop,
	modalCadUsuario: false,
	modalWhiteLabel: false,
	modalGrupoBetSinais: false,
	CadUsuario: noop,
	WhiteLabel: noop,
	GrupoBetSinais: noop,
	HandleGoToSite: noop,
}

export const CriarClienteContext = createContext<ICriarClienteContext>(defaultState)
CriarClienteContext.displayName = 'CriarClienteContext'

export const CriarClienteProvider = ({
	children,
}: ICriarClienteContextState): JSX.Element => {
	const [clientes, setClientes] = useState<{}>(defaultState.clientes)
	const [adicionarModal, setAdicionarModal] = useState<boolean>(defaultState.adicionarModal)
	const [modalCadUsuario, setModalCadUsuario] = useState<boolean>(defaultState.modalCadUsuario)
	const [modalWhiteLabel, setModalWhiteLabel] = useState<boolean>(defaultState.modalWhiteLabel)
	const [modalGrupoBetSinais, setModalGrupoBetSinais] = useState<boolean>(defaultState.modalGrupoBetSinais)
	const [clienteSendoEditado, setClienteSendoEditado] = useState<any>(defaultState.clienteSendoEditado)
	const [parametrosDelete, setParametrosDelete] = useState<any>(defaultState.parametrosDelete)

	const GetClientes = async () => {
		const response = await GetJogosModelo()
		setClientes(response)
		
		//axios.get('http://localhost:5000/api/clientes')
	}
	

	const EditClienteData = (jogoReplicado: any) => {
		let objCliente: any = [];
		let editData: any = {};

		console.log(jogoReplicado, 'jogo a ser replicado')


	
        objCliente = [{
			grupoOrigemMensagem: jogoReplicado[1],
            grupoRepasseMensagem: 'chatId',
            nomeGrupo: 'Nome'
        }]

        editData = {
			grupoOrigemMensagem: jogoReplicado[1],
            grupoRepasseMensagem: objCliente['chatId'],
            nomeGrupo: objCliente['Nome']
        }

		setClienteSendoEditado({ objCliente, editData })
		
	}

	const EditCliente = async (objCliente: any) => {
		console.log(objCliente, ': cliente sendo criado')
		const response  = await InsertReplicador(objCliente)
		return response
	}

	const DeleteCliente = () => {

	}

	const AddCliente = (objCliente: {}) => {
        //cliente completo
		console.log(objCliente)

		//axios.post('http://localhost:5000/api/clientes', objCliente)
	}

	const CadUsuario = async (objUsuario: any) => {

		const response = await InsertCadUsuario(objUsuario).then(()=>{
			alert("Subdominio Cadastrado")
		}).catch((error)=> {
			alert(error)
		})

		return response

	}

	const WhiteLabel = async (objUsuario: any) => {
		const response = await InsertWhiteLabel(objUsuario)
		.then(()=>{
			alert("Site Configurado") 
		}).catch((error)=> {
			alert(error)
		})

		return response
	}

	const GrupoBetSinais = async (clientId: any) => {
		const objUsuario = {
			ClientId: clientId,
		}

		const response = await InsertGrupoBetSinais(objUsuario).then(()=>{
			alert("Jogos configurados") 
		}).catch((error)=> {
			alert("Os jogos já estão configurados.")
		})

		return response
	}

	const HandleAdicionarModal = () => {
		setAdicionarModal(!adicionarModal)
	}

	const HandleModalCadUsuario = () => {
		setModalCadUsuario(!modalCadUsuario)
	}

	const HandleModalWhiteLabel = () => {
		setModalWhiteLabel(!modalWhiteLabel)
	}

	const HandleModalGrupoBetSinais = () => {
		setModalGrupoBetSinais(!modalGrupoBetSinais)
	}

	const HandleGoToSite = async (clientId: any) => {
		console.log(clientId)
		const response = await GetSubdominio(clientId)
		.then((response)=>{
			console.log(response.data[0])
			window.open(`https://smarttips.app/${response.data[0]}`)
		}).catch((error) => {
			alert("Site não encontrado")
		})
	}

	

	return (
		<CriarClienteContext.Provider value={{
			clientes,
			setClientes,
			adicionarModal,
			clienteSendoEditado,
			setClienteSendoEditado,
			HandleAdicionarModal,
			AddCliente,
			EditClienteData,
			EditCliente,
			setParametrosDelete,
			parametrosDelete,
			GetClientes,
			HandleModalCadUsuario,
			HandleModalWhiteLabel,
			HandleModalGrupoBetSinais,
			modalCadUsuario,
			modalWhiteLabel,
			modalGrupoBetSinais,
			CadUsuario,
			WhiteLabel,
			GrupoBetSinais,
			HandleGoToSite
		}}>
			{children}
		</CriarClienteContext.Provider>
	)
}

export const useCriarCliente = (): ICriarClienteContext => useContext(CriarClienteContext)
