import CrudTopbar from "../../components/Crud/crud-topbar";
import CrudTabela from "../../components/Crud/crud-tabela";
import Modal from "../../components/Modal";
import { useGrupos } from "../../context/grupos.context";
import { Button } from "@mui/material";
import { useEffect } from "react";
import { useAuth } from "../../context/auth.context";

const Grupos = () => {
  const { HandleAdicionarModal, adicionarModal, AddGrupo, EditGrupo, grupos, EditGrupoData, setGrupoSendoEditado, grupoSendoEditado, parametrosDelete, setParametrosDelete, GetGrupos, setGrupos, DeleteGrupo } = useGrupos();
 
  useEffect(()=>{
    const verifAuth = () => {
      const verifToken = localStorage.getItem('@Token:SmarttipsAdmin')
      if (verifToken == null || verifToken == '') {
        window.location.href = './login'
      }
    }
    verifAuth()
  }, [])


  useEffect(() => {
		GetGrupos()

	}, [])

  return (
    <>
      <CrudTopbar nomeDaPagina="Grupos" onClick={() => HandleAdicionarModal()} />
      <CrudTabela
        nome="Lista de Grupos"
        columns={[...grupos.columns, {
          label: "Ações",
          options: {
            customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
              return (
                <>
                  <Button onClick={() => {
                    console.log(value, tableMeta)
                    EditGrupoData(tableMeta.rowData)

                  }}>
                    Editar
                  </Button>
                  <Button onClick={() => {
                    console.log(value, tableMeta)
                    setParametrosDelete({ IdChat: tableMeta.rowData[0]})
                  }}>
                    Remover
                  </Button>
                </>
              )
            }
          }
        }]}
        data={grupos.data} 
        />
      <Modal
        parametrosOcultos={[{ ClientId: localStorage.getItem('@ClientId:SmarttipsAdmin')}]}
        campos={[{ NomeGrupo: "Nome do Grupo", DescricaoGrupo: "Descrição do Grupo", ChatId: "ChatId do Telegram" }]}
        nome="Adicionar Grupo"
        open={adicionarModal}
        onClose={() => HandleAdicionarModal()}
        Metodo={AddGrupo}
      />

      {
        grupoSendoEditado.editData !== undefined && <Modal
          campos={grupoSendoEditado.objCliente}
          EditData={grupoSendoEditado.editData}
          nome="Editar Cliente"
          open={grupoSendoEditado.editData !== undefined}
          onClose={() => setGrupoSendoEditado({})}
          Metodo={EditGrupo}
        />
      }
      
      {
        parametrosDelete !== null && <Modal
          Delete
          nome="Deletar Cliente"
          open={parametrosDelete !== null}
          onClose={() => setParametrosDelete(null)}
          Metodo={DeleteGrupo}
        />
      }
    </>
  )
}

export default Grupos;
