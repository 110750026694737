import CrudTopbar from "../../components/Crud/crud-topbar";
import CrudTabela from "../../components/Crud/crud-tabela";
import Modal from "../../components/Modal";
import { useLinks } from "../../context/linksBot.context";
import { Button } from "@mui/material";
import { useEffect } from "react";
import { useAuth } from "../../context/auth.context";

const Links = () => {
  const { HandleAdicionarModal, adicionarModal, AddLink, EditLink, links, EditLinkData, setLinkSendoEditado, linkSendoEditado, parametrosDelete, setParametrosDelete, GetLinks, setLinks, DeleteLink } = useLinks();

  useEffect(()=>{
    const verifAuth = () => {
      const verifToken = localStorage.getItem('@Token:SmarttipsAdmin')
      if (verifToken == null || verifToken == '') {
        window.location.href = './login'
      }
    }
    verifAuth()
  }, [])

  useEffect(()=>{
    GetLinks()
  })

  return (
    <>
      <CrudTopbar nomeDaPagina="Links do Bot" onClick={() => HandleAdicionarModal()} />
      <CrudTabela
        nome="Lista de Links"
        columns={[...links.columns, {
          label: "Ações",
          options: {
            customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
              return (
                <>
                  <Button onClick={() => {
                    console.log(value, tableMeta)
                    EditLinkData(tableMeta.rowData)

                  }}>
                    Editar
                  </Button>
                  <Button onClick={() => {
                    console.log(value, tableMeta)
                    setParametrosDelete({ id: tableMeta.rowData[0] })
                  }}>
                    Remover
                  </Button>
                </>
              )
            }
          }
        }]}
        data={links.data} 
        />
      <Modal
        parametrosOcultos={[{ ClientId: localStorage.getItem('@ClientId:SmarttipsAdmin'), AfiliadoId: localStorage.getItem('@ClientId:SmarttipsAdmin')}]}
        campos={[{ TextoGrupo: "Texto", LinkGrupo: "Link"}]}
        nome="Adicionar Link"
        open={adicionarModal}
        onClose={() => HandleAdicionarModal()}
        Metodo={AddLink}
      />

      {
        linkSendoEditado.editData !== undefined && <Modal
          campos={linkSendoEditado.objLink}
          EditData={linkSendoEditado.editData}
          nome="Editar Link"
          open={linkSendoEditado.editData !== undefined}
          onClose={() => setLinkSendoEditado({})}
          Metodo={EditLink}
        />
      }
      
      {
        parametrosDelete !== null && <Modal
          Delete
          nome="Deletar Link"
          open={parametrosDelete !== null}
          onClose={() => setParametrosDelete(null)}
          Metodo={DeleteLink}
        />
      }
    </>
  )
}

export default Links;
