import { Button } from '@mui/material';
import styled from 'styled-components';

export const Container = styled.div`
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #2f2f39;
    h1 {
        font-size: 24px;
        font-weight: 500;
        color: #fff;
    }
`;

export const ButtonAdd = styled(Button)`
    width: fit-content;
`;