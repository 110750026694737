import CrudTopbar from "../../components/Crud/crud-topbar";
import CrudTabela from "../../components/Crud/crud-tabela";
import Modal from "../../components/Modal";
import { usePerguntas } from "../../context/perguntas.context";
import { Button } from "@mui/material";
import { useEffect } from "react";
import { useAuth } from "../../context/auth.context";

const PerguntasBot = () => {
  const { HandleAdicionarModal, adicionarModal, AddPergunta, EditPergunta, perguntas, EditPerguntaData, setPerguntaSendoEditado, perguntaSendoEditado, parametrosDelete, setParametrosDelete, GetPerguntas, setPerguntas, DeletePergunta } = usePerguntas();

  useEffect(()=>{
    const verifAuth = () => {
      const verifToken = localStorage.getItem('@Token:SmarttipsAdmin')
      if (verifToken == null || verifToken == '') {
        window.location.href = './login'
      }
    }
    verifAuth()
  }, [])

  useEffect(()=>{
    const clientId = localStorage.getItem('@ClientId:SmarttipsAdmin')
    GetPerguntas(clientId)
  })

  return (
    <>
      <CrudTopbar nomeDaPagina="Perguntas" onClick={() => HandleAdicionarModal()} />
      <CrudTabela
        nome="Lista de Perguntas"
        columns={[...perguntas.columns, {
          label: "Ações",
          options: {
            customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
              return (
                <>
                  <Button onClick={() => {
                    console.log(value, tableMeta)
                    EditPerguntaData(tableMeta.rowData)

                  }}>
                    Editar
                  </Button>
                  <Button onClick={() => {
                    console.log(value, tableMeta)
                    setParametrosDelete({ id: tableMeta.rowData[0] })
                  }}>
                    Remover
                  </Button>
                </>
              )
            }
          }
        }]}
        data={perguntas.data} 
        />
      <Modal
        parametrosOcultos={[{ ClientId: localStorage.getItem('@ClientId:SmarttipsAdmin')}]}
        campos={[{ IndexState: "Posição da pergunta", TextoPergunta: "Pergunta" }]}
        nome="Adicionar Pergunta"
        open={adicionarModal}
        onClose={() => HandleAdicionarModal()}
        Metodo={AddPergunta}
      />

      {
        perguntaSendoEditado.editData !== undefined && <Modal
          campos={perguntaSendoEditado.objPergunta}
          EditData={perguntaSendoEditado.editData}
          nome="Editar Pergunta"
          open={perguntaSendoEditado.editData !== undefined}
          onClose={() => setPerguntaSendoEditado({})}
          Metodo={EditPergunta}
        />
      }
      
      {
        parametrosDelete !== null && <Modal
          Delete
          nome="Deletar Pergunta"
          open={parametrosDelete !== null}
          onClose={() => setParametrosDelete(null)}
          Metodo={DeletePergunta}
        />
      }
    </>
  )
}

export default PerguntasBot;
