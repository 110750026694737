import React from 'react'
import './App.css'
import Router from './routing'
import { AuthProvider } from './context/auth.context'
import { FetchProvider } from './context/fetch.context'
import { ThemeProvider } from '@mui/material/styles'
import { theme } from './Theme'
import { ClientesProvider } from './context/clientes.context'
import { CasasDeApostasProvider } from './context/casasDeApostas.context'
import { GruposProvider } from './context/grupos.context'
import { SinaisProvider } from './context/sinais.context'
import { LeadsProvider } from './context/leads.context'
import { CriarClienteProvider } from './context/criarCliente.context'
import { CriarBotProvider } from './context/criarBot.context'
import { LinksProvider } from './context/linksBot.context'
import { PerguntasProvider } from './context/perguntas.context'

export default function App(): JSX.Element {
  return (
    <FetchProvider>
      <AuthProvider>
        <ThemeProvider theme={theme}>
        
        <CriarClienteProvider>
          <CriarBotProvider>
              <ClientesProvider>
                <PerguntasProvider>


      
                <LinksProvider>
            
                <CasasDeApostasProvider>
                  <GruposProvider>
                    <SinaisProvider>
                      <LeadsProvider>
                        <Router />
                      </LeadsProvider>
                    </SinaisProvider>
                  </GruposProvider>
                </CasasDeApostasProvider>
                </LinksProvider>
                </PerguntasProvider>
              </ClientesProvider>
            </CriarBotProvider>
          </CriarClienteProvider>
          
        </ThemeProvider>
      </AuthProvider>
    </FetchProvider>
  )
}
