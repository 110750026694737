import React, { useState } from 'react'
import { ContentBackground,PaiLogin,Cardlogin, StyledImage, PaiInput,  StyledInput, InputsDiv, BtLogin, CheckBox, CheckBoxLabel, DivCheckBox, EsqueciMinhaSenha, DivFooterInputs, DivFooterButton, H2, P} from './styles'
import logo from '.././../assets/LOGO2.png'
import PersonIcon from '@mui/icons-material/Person';
import LockIcon from '@mui/icons-material/Lock';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

function Cardinput() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  function handleUsernameChange(e: { target: { value: React.SetStateAction<string>; }; }) {
    setUsername(e.target.value);
  }

  function handlePasswordChange(e: { target: { value: React.SetStateAction<string>; }; }) {
    setPassword(e.target.value);
  }
  
  return (
    <form>
      <H2>Redefinir Senha</H2>

      <P>Nova senha</P>
      <InputsDiv>
      <StyledInput 
        type="text"
        placeholder="4 OU MAIS CARACTERES"
        value={username}
        onChange={handleUsernameChange}
      />
      <VisibilityOffIcon 
        style={{
          position: 'absolute',
          right: 0,
          marginTop: '15px',
          marginRight: '10px',
          fontSize: '30px'
        }}
      />
      </InputsDiv>

      <P>Confirmar senha</P>
      <InputsDiv>
      <StyledInput
        type="password"
        placeholder="REPETIR A SENHA"
        value={password}
        onChange={handlePasswordChange}
      />
      <VisibilityOffIcon 
        style={{
          position: 'absolute',
          right: 0,
          marginTop: '15px',
          marginRight: '10px',
          fontSize: '30px'
        }}
      />
      </InputsDiv>

      <BtLogin id="submit" type="submit">REDEFINIR</BtLogin>
      <DivFooterButton>
      <EsqueciMinhaSenha>Voltar para o login</EsqueciMinhaSenha>
      </DivFooterButton>
      {/*Não tem uma conta? Criar uma conta*/}
    </form>
  );
}


const RedefinirSenha: React.FC = () => {
  return (
    <>
  <ContentBackground>
    <PaiLogin> 
    <StyledImage src={logo} alt="Input Icon" />
      <Cardlogin>
        <PaiInput>
          <Cardinput/>
        </PaiInput>
      </Cardlogin>
    </PaiLogin>
  </ContentBackground>
    

    </>
  )
}

export default RedefinirSenha
