import CrudTopbar from "../../components/Crud/crud-topbar";
import CrudTabela from "../../components/Crud/crud-tabela";
import Modal from "../../components/Modal";
import { useLeads } from "../../context/leads.context";
import { Button } from "@mui/material";
import { useEffect } from "react";
import { useAuth } from "../../context/auth.context";

const LeadsLead = () => {
  const { HandleAdicionarModal, adicionarModal, AddLead, EditLead, leads, EditLeadData, setLeadSendoEditado, leadSendoEditado, parametrosDelete, setParametrosDelete, GetLeads, setLeads, Teste, DeleteLead } = useLeads();

  const {pegarSubdomain} = useAuth()

  
  useEffect(()=>{
    const verifAuth = () => {
      const verifToken = localStorage.getItem('@Token:SmarttipsAdmin')
      if (verifToken == null || verifToken == '') {
        window.location.href = './login'
      }
    }
    verifAuth()
  }, [])

  useEffect(() => {
    const clientId = localStorage.getItem('@ClientId:SmarttipsAdmin')
		GetLeads(clientId)

   

	}, [])

  useEffect(()=>{
    pegarSubdomain(localStorage.getItem('@ClientId:SmarttipsAdmin'))
  }, [leads])

  return (
    <>
      <CrudTopbar nomeDaPagina="Leads" onClick={() => HandleAdicionarModal()} />
      <CrudTabela
        nome="Lista de Leads"
        columns={[...leads.columns, {
          label: "Ações",
          options: {
            customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
              return (
                <>
                  {/* <Button onClick={() => {
                    console.log(value, tableMeta)
                    EditLeadData(tableMeta.rowData)

                  }}>
                    Editar
                  </Button> */}
                  <Button onClick={() => {
                    console.log(value, tableMeta)
                    setParametrosDelete({ id: tableMeta.rowData[0] })
                  }}>
                    Remover
                  </Button>
                </>
              )
            }
          }
        }]}
        data={leads.data} 
        />
      <Modal
        parametrosOcultos={[{ ClientId: localStorage.getItem('@ClientId:SmarttipsAdmin')}]}
        campos={[{ nome: "Nome", email: "Email", telefone: "Telefone"}]}
        nome="Adicionar Lead"
        open={adicionarModal}
        onClose={() => HandleAdicionarModal()}
        Metodo={AddLead}
      />

      {
        leadSendoEditado.editData !== undefined && <Modal
          campos={leadSendoEditado.objLead}
          EditData={leadSendoEditado.editData}
          nome="Editar Lead"
          open={leadSendoEditado.editData !== undefined}
          onClose={() => setLeadSendoEditado({})}
          Metodo={EditLead}
        />
      }
      
      {
        parametrosDelete !== null && <Modal
          Delete
          nome="Deletar Lead"
          open={parametrosDelete !== null}
          onClose={() => setParametrosDelete(null)}
          Metodo={DeleteLead}
        />
      }
    </>
  )
}

export default LeadsLead;
