import axios from 'axios'
axios.defaults.headers.get['Content-Type'] ='application/json;charset=utf-8';
axios.defaults.headers.get['Access-Control-Allow-Origin'] = '*';

export const GetLeadsClientId = async (ClientId: any) => {
    try{
        const response = await axios.get(`https://admin.linerasistemas.com.br/api/Lead/GetLeadsClientId?ClientId=${ClientId}`)

        return response.data;

    } catch (error) {
        console.log(error)
    }
}

//clientId
//textoAcao
//afiliadoid
//nomeBot
export const InsertBotConfig = async (bot: { textoAcao: string, afiliadoId: any, nomeBot: any}) => {
    let url = `https://admin.linerasistemas.com.br/api/Lead/InsertBotConfig`
  
  
    const response = await axios.post(url, bot, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
    
    return response;
  
}

export const InsertBotGrupo = async (bot: { clientId: any, textoGrupo: any, linkGrupo: any, afiliadoId: any}) => {
    let url = `https://admin.linerasistemas.com.br/api/Lead/InsertBotGrupo`
  
  
    const response = await axios.post(url, bot, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
    
    return response;
  
}

export const GetBotPorCliente = async (ClientId: any) => {
  try{
      const response = await axios.get(`https://admin.linerasistemas.com.br/api/Bot/GetBotsPorCliente?AfiliadoId=${ClientId}`)

      return response.data;

  } catch (error) {
      console.log(error)
  }
}




export const UpdateBotConfig = async (AfiliadoId: any, TextoAcao: any, Bot: any, NomeBot: any, TextoBoasVindas: any, Id: any) => {
  let url = `https://admin.linerasistemas.com.br/api/Bot/UpdateBotConfig?AfiliadoId=${AfiliadoId}&TextoAcao=${TextoAcao}&Bot=${Bot}&NomeBot=${NomeBot}&TextoBoasVindas=${TextoBoasVindas}&Id=${Id}`


  const response = await axios.post(url, {
    headers: {
      'Content-Type': 'application/json'
    }
  })
  
  return response;

}

export const GetLinksTelegram = async (ClientId: any) => {
  try{
      const response = await axios.get(`https://admin.linerasistemas.com.br/api/Bot/GetLinksTelegram?AfiliadoId=${ClientId}`)

      return response.data;

  } catch (error) {
      console.log(error)
  }
}

//https://localhost:7086/api/Bot/UpdateBotLink?TextoGrupo=0&LinkGrupo=0&AfiliadoId=0&Id=01930

export const UpdateBotLink = async (TextoGrupo: any, LinkGrupo: any, AfiliadoId: any, Id: any) => {
  let url = `https://admin.linerasistemas.com.br/api/Bot/UpdateBotLink?TextoGrupo=${TextoGrupo}&LinkGrupo=${LinkGrupo}&AfiliadoId=${AfiliadoId}&Id=${Id}`


  const response = await axios.post(url, {
    headers: {
      'Content-Type': 'application/json'
    }
  })
  
  return response;

}


//https://localhost:7086/api/Lead/DeleteBotConfig/49

export const DeleteBotConfig = async (id: any) => {
  let url = `https://admin.linerasistemas.com.br/api/Lead/DeleteBotConfig/${id}`


  const response = await axios.delete(url, {
    headers: {
      'Content-Type': 'application/json'
    }
  })
  
  return response;

}

export const DeleteBotLink = async (id: any) => {
  let url = `https://admin.linerasistemas.com.br/api/Lead/DeleteBotLink/${id}`


  const response = await axios.delete(url, {
    headers: {
      'Content-Type': 'application/json'
    }
  })
  
  return response;

}

//https://localhost:7086/api/Lead/DeleteBotLink/224353


export const DeletarLead = async (id: any) => {
  let url = `https://admin.linerasistemas.com.br/api/Lead/DeleteLead/${id}`


  const response = await axios.delete(url, {
    headers: {
      'Content-Type': 'application/json'
    }
  })
  
  return response;
}


export const GetLeadsClientIdEspecial = async (responsavel: any) => {
  let url = `https://admin.linerasistemas.com.br/api/Lead/GetTodosLeadsAdmin?RESPONSAVEL=${responsavel}`;

  try{
    const response = await axios.get(url)

    return response.data;

  } catch (error) {
    console.log(error)
  }
}

export const InsertLead = async (lead: { nome: any, email: any, telefone: any, clientId: any}) => {
  let url = `https://admin.linerasistemas.com.br/api/Lead/InsertLead`

  	// api/Lead/InsertLead  
		// lead: {
		// nome: any,
		// email: any,
		// telefone: any,
		// clientId: any
		// }

  const response = await axios.post(url, lead, {
    headers: {
      'Content-Type': 'application/json'
    }
  })
  
  return response;

}

//api/Bot/InsertBotLeadGrupo?ClientId=99&TextoGrupo=teste2.com&LinkGrupo=www.com.org&AfiliadoId=99'

export const InsertLinkBot = async (ClientId: any, TextoGrupo: any, LinkGrupo: any, AfiliadoId: any) => {
  let url = `https://admin.linerasistemas.com.br/api/Bot/InsertBotLeadGrupo?ClientId=${ClientId}&TextoGrupo=${TextoGrupo}&LinkGrupo=${LinkGrupo}&AfiliadoId=${AfiliadoId}`

  const response = await axios.post(url, {
    headers: {
      'Content-Type': 'application/json'
    }
  })
  
  return response;

}