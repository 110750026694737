import { ReactElement, createContext, useContext, useEffect, useState } from 'react'
import { noop } from '../utils'
import axios from 'axios';
import { GetLeadsClientId, DeletarLead, GetLeadsClientIdEspecial, InsertLead } from '../services/leads';

export interface ILeadsContext {
	leads: any,
	setLeads: (leads: any) => void,
	adicionarModal: boolean,
	leadSendoEditado: any,
	setLeadSendoEditado: (c: {}) => void,
	HandleAdicionarModal: () => void,
	AddLead: (objLead: any) => void,
	EditLeadData: (dadosCleiente: any) => void,
	EditLead: any,
	parametrosDelete: any,
	setParametrosDelete: (parametros: any) => void,
	GetLeads: (clientId: any) => void;
	Teste: () => void;
	DeleteLead: any;
}

export interface ILeadsContextState {
	children: ReactElement
}

const defaultState: ILeadsContext = {
	leads: {
		"columns": ["Loading", "Loading", "Loading", "Loading"],
		"data": [
			["Loading", "Loading", "Loading", "Loading"],
		]
	},
	setLeads: noop,
	adicionarModal: false,
	HandleAdicionarModal: noop,
	leadSendoEditado: {},
	setLeadSendoEditado: noop,
	AddLead: noop,
	EditLeadData: noop,
	EditLead: noop,
	setParametrosDelete: noop,
	parametrosDelete: null,
	GetLeads: noop,
	Teste: noop,
	DeleteLead: noop,
}

export const LeadsContext = createContext<ILeadsContext>(defaultState)
LeadsContext.displayName = 'LeadsContext'

export const LeadsProvider = ({
	children,
}: ILeadsContextState): JSX.Element => {
	const [leads, setLeads] = useState<{}>(defaultState.leads)
	const [adicionarModal, setAdicionarModal] = useState<boolean>(defaultState.adicionarModal)
	const [leadSendoEditado, setLeadSendoEditado] = useState<any>(defaultState.leadSendoEditado)
	const [parametrosDelete, setParametrosDelete] = useState<any>(defaultState.parametrosDelete)

	const GetLeads = async (clientId: any) => {
		let response;  
		if(localStorage.getItem('@Responsavel:SmarttipsAdmin')){
			//metodo especial
			response = await GetLeadsClientIdEspecial(localStorage.getItem('@Responsavel:SmarttipsAdmin'))
		} else {
			response = await GetLeadsClientId(clientId)
		}
		
		console.log(response.columns[response.columns.length - 1])
		
		const validacaoAdmin = localStorage.getItem('@Responsavel:SmarttipsAdmin') != null
		if(!validacaoAdmin){
			response.columns[response.columns.length - 1] = {
				name: "ativo",
				label: "Ativo",
				options: {
					display: 'false'
				}
			}
		}
		
		setLeads(response)
	}

	const Teste = async () => {
		const response = await GetLeadsClientId('50')
		return response
	}

	const EditLeadData = (dadosLeads: any) => {
		let objLead: any = [];
		let editData: any = {};

		//@ts-ignore
		leads.columns.forEach((c, index) => {
			objLead.push({ [c]: c })
		})
		//@ts-ignore
		leads.columns.forEach((c, index) => {
			editData[c] = dadosLeads[index]
		})

		setLeadSendoEditado({ objLead, editData })
	}

	const EditLead = (objLead: {}) => {
		console.log(objLead)
	}

	const DeleteLead = async () => {
		const response = await DeletarLead(parametrosDelete["id"]).then(()=>{
			alert('Lead removido com sucesso!')
			setParametrosDelete(null)
		}).catch((err)=>{
			alert(err)
		})
		return response
	}

	const AddLead = async (objLead: any) => {
		console.log(objLead)

		const response = await InsertLead(objLead)
		.then((response)=>{
			alert('Lead adicionado com sucesso!')
		}).catch((err) => {
			alert('Ocorreu um erro.')
		})

		setAdicionarModal(!adicionarModal)
		return response
		// api/Lead/InsertLead  
		// lead: {
		// nome: any,
		// email: any,
		// telefone: any,
		// clientId: any
		// }

		//axios.post('http://localhost:5000/api/leads', objLead)
	}

	const HandleAdicionarModal = () => {
		setAdicionarModal(!adicionarModal)
	}

	

	return (
		<LeadsContext.Provider value={{
			leads,
			setLeads,
			adicionarModal,
			leadSendoEditado,
			setLeadSendoEditado,
			HandleAdicionarModal,
			AddLead,
			EditLeadData,
			EditLead,
			setParametrosDelete,
			parametrosDelete,
			GetLeads,
			Teste,
			DeleteLead
		}}>
			{children}
		</LeadsContext.Provider>
	)
}

export const useLeads = (): ILeadsContext => useContext(LeadsContext)
