import { ReactElement, createContext, useContext, useEffect, useState } from 'react'
import { noop } from '../utils'
import { DeletarGrupo, GetChatIdGrupo, InsertGrupo } from '../services/grupos';

export interface IGruposContext {
	grupos: any,
	setGrupos: (grupos: any) => void,
	adicionarModal: boolean,
	grupoSendoEditado: any,
	setGrupoSendoEditado: (g: {}) => void,
	HandleAdicionarModal: () => void,
	AddGrupo: (objGrupo: any) => void,
	EditGrupoData: (dadosGrupo: any) => void,
	EditGrupo: any,
	parametrosDelete: any,
	setParametrosDelete: (parametros: any) => void,
	GetGrupos: () => void;
	DeleteGrupo: any;
}

export interface IGruposContextState {
	children: ReactElement
}

const defaultState: IGruposContext = {
	grupos: {
		"columns": ["Loading", "Loading", "Loading", "Loading"],
		"data": [
			["Loading", "Loading", "Loading", "Loading"],
		]
	},
	setGrupos: noop,
	adicionarModal: false,
	HandleAdicionarModal: noop,
	grupoSendoEditado: {},
	setGrupoSendoEditado: noop,
	AddGrupo: noop,
	EditGrupoData: noop,
	EditGrupo: noop,
	setParametrosDelete: noop,
	parametrosDelete: null,
	GetGrupos: noop,
	DeleteGrupo: noop,
}

export const GruposContext = createContext<IGruposContext>(defaultState)
GruposContext.displayName = 'GruposContext'

export const GruposProvider = ({
	children,
}: IGruposContextState): JSX.Element => {
	const [grupos, setGrupos] = useState<{}>(defaultState.grupos)
	const [adicionarModal, setAdicionarModal] = useState<boolean>(defaultState.adicionarModal)
	const [grupoSendoEditado, setGrupoSendoEditado] = useState<any>(defaultState.grupoSendoEditado)
	const [parametrosDelete, setParametrosDelete] = useState<any>(defaultState.parametrosDelete)

	const GetGrupos = async () => {
		const response = await GetChatIdGrupo(localStorage.getItem('@ClientId:SmarttipsAdmin'))
		setGrupos(response)

		//axios.get('http://localhost:5000/api/clientes')
	}

	const EditGrupoData = (dadosGrupos: any) => {
		let objGrupo: any = [];
		let editData: any = {};

		//@ts-ignore
		grupos.columns.forEach((c, index) => {
			objGrupo.push({ [c]: c })
		})
		//@ts-ignore
		grupos.columns.forEach((c, index) => {
			editData[c] = dadosGrupos[index]
		})

		setGrupoSendoEditado({ objGrupo, editData })
	}

	const EditGrupo = (objGrupo: {}) => {
		console.log(objGrupo)
	}

	const DeleteGrupo = async () => {
		const response = await DeletarGrupo(parametrosDelete["IdChat"]).then(()=>{
			alert("Grupo Removido")
			setParametrosDelete(null)
		}).catch((err) => {
			alert(err)
		});
		return response
	}

	const AddGrupo = async (objGrupo: any) => {
		console.log(objGrupo)
		const response = await InsertGrupo(objGrupo["ClientId"], objGrupo["NomeGrupo"], objGrupo["DescricaoGrupo"], objGrupo["ChatId"])
		.then(()=>{
			alert("Grupo cadastrado com sucesso!")
		}).catch((err)=>{
			alert("Ocorreu um erro.")
		})
		setAdicionarModal(!adicionarModal)
		return response
		//axios.post('http://localhost:5000/api/clientes', objCliente)
	}

	const HandleAdicionarModal = () => {
		setAdicionarModal(!adicionarModal)
	}

	

	return (
		<GruposContext.Provider value={{
			grupos,
			setGrupos,
			adicionarModal,
			grupoSendoEditado,
			setGrupoSendoEditado,
			HandleAdicionarModal,
			AddGrupo,
			EditGrupoData,
			EditGrupo,
			setParametrosDelete,
			parametrosDelete,
			GetGrupos,
			DeleteGrupo
		}}>
			{children}
		</GruposContext.Provider>
	)
}

export const useGrupos = (): IGruposContext => useContext(GruposContext)
