import styled from 'styled-components'

export const ContainerMonitor = styled.div`
display: grid;
grid-template-columns: 1fr 1fr 1fr 1fr; // define a largura mínima e máxima dos cards
gap: 15px;
padding: 30px;
box-sizing: border-box;
justify-items: center; // centraliza os cards horizontalmente
@media screen and (max-width: 1024px){
  grid-template-columns: 1fr 1fr 1fr;
}
@media screen and (max-width: 768px){
  display: grid;
  grid-template-columns: 1fr 1fr; // altera a largura mínima e máxima dos cards para telas menores
  gap: 10px;
  padding: 30px;
  box-sizing: border-box;
  justify-items: center; // centraliza os cards horizontalmente
}
`

export const CardMonitor = styled.div`
display: flex;
flex-direction: column;
height: 180px;
padding: 10px;
background: #2f2f39;
box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.35);
border-radius: 12px;
max-width: 300px;
width: 100%;
justify-content: center;
position: relative;
`


export const TextoNome = styled.p`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  margin: 5px;
  margin-top: 10px;
  text-align: center;
  text-transform: uppercase;
  color: #e2c03c;
`;

export const TextoAtt = styled.p`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  text-align: center;
  color: #ffffff;
  margin: 5px;
  margin-bottom: 10px;
`;

export const TextStatus = styled.p`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: white;
  text-align: center;
`;

export const CardStatusA = styled.div`
  background: #41b878;
  opacity: 0.8;
  border-radius: 4px;
  text-align: center;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  
`;

export const ContainerIMG = styled.div`
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
`;

export const MuiCircle = styled.div`
  display: block
  align-items: top;
  margin-left: 105%;
  width: 8.5%;
  height: 10px;
`;

export const CardStatusB = styled.div`
  border-radius: 4px;
  display: flex;  
  flex-direction: column;
  justify-content: center;
  align-self: center;
  text-align: center;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  background: #ef2f3c;
  opacity: 1;
  border-radius: 4px;
  width: 70px;
  
  height: 35px;
`;

export const TextStatusB = styled.p`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: black;
`;


