import CrudTopbar from "../../components/Crud/crud-topbar";
import CrudTabela from "../../components/Crud/crud-tabela";
import Modal from "../../components/Modal";
import { useCasasDeApostas } from "../../context/casasDeApostas.context";
import { Button } from "@mui/material";
import { useEffect } from "react";
import { useAuth } from "../../context/auth.context";

const CasasDeApostas = () => {
  const { HandleAdicionarModal, adicionarModal, AddCasa, EditCasa, casas, EditCasaData, setCasaSendoEditada, casaSendoEditada, parametrosDelete, setParametrosDelete, GetCasas, setCasas} = useCasasDeApostas();

  useEffect(()=>{
    const verifAuth = () => {
      const verifToken = localStorage.getItem('@Token:SmarttipsAdmin')
      if (verifToken == null || verifToken == '') {
        window.location.href = './login'
      }
    }
    verifAuth()
  }, [])

  useEffect(() => {
		const casas = GetCasas()
		setCasas(casas)
	}, [])

  return (
    <>
      <CrudTopbar nomeDaPagina="Casas de Apostas" onClick={() => HandleAdicionarModal()} />
      <CrudTabela
        nome="Lista de Casas de Apostas"
        columns={[...casas.columns, {
          label: "Ações",
          options: {
            customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
              return (
                <>
                  <Button onClick={() => {
                    console.log(value, tableMeta)
                    EditCasaData(tableMeta.rowData)

                  }}>
                    Editar
                  </Button>
                  <Button onClick={() => {
                    console.log(value, tableMeta)
                    setParametrosDelete({ parametro1: tableMeta.rowData[0], parametro2: tableMeta.rowData[1] })
                  }}>
                    Remover
                  </Button>
                </>
              )
            }
          }
        }]}
        data={casas.data} 
        />
      <Modal
        parametrosOcultos={[{ ClientId: 8 }]}
        campos={[{ NomeCasa: "Nome da Casa", LinkCasa: "Link da Casa" }]}
        nome="Adicionar Casa"
        open={adicionarModal}
        onClose={() => HandleAdicionarModal()}
        Metodo={AddCasa}
      />

      {
        casaSendoEditada.editData !== undefined && <Modal
          campos={casaSendoEditada.objCasa}
          EditData={casaSendoEditada.editData}
          nome="Editar Casa"
          open={casaSendoEditada.editData !== undefined}
          onClose={() => setCasaSendoEditada({})}
          Metodo={EditCasa}
        />
      }
      
      {
        parametrosDelete !== null && <Modal
          Delete
          nome="Deletar Casa"
          open={parametrosDelete !== null}
          onClose={() => setParametrosDelete(null)}
          Metodo={EditCasa}
        />
      }
    </>
  )
}

export default CasasDeApostas;
