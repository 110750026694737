import {
  BrowserRouter,
  Route,
  Routes,
  Navigate,
  Outlet,
  useLocation,
} from 'react-router-dom'
import { useAuth } from '../context/auth.context'
import Layoult from '../pages/Layoult'
import RecuperarSenha from '../pages/RecuperarSenha'
import RedefinirSenha from '../components/CardRedefinirSenha'
import Loginpage from '../pages/Login'
import Clientes from '../pages/Clientes'
import CasasDeApostas from '../pages/CasasDeApostas'
import Grupos from '../pages/Grupos'
import Sinais from '../pages/Sinais'
import LeadsCliente from '../pages/LeadsCliente'
import CriarBot from '../pages/CriarBot'
import Replicador from '../pages/Replicador'
import CriarWebApp from '../pages/CriarWebApp'
import Links from '../pages/Links'
import PerguntasBot from '../pages/PerguntasBot'
import MonitorPage from '../pages/Monitor'
import MonitorGrupos from '../pages/MonitorGrupos'

export default function Router(): JSX.Element {
 
  return (

    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layoult />}>
          <Route path="/" element={<LeadsCliente />} />
          <Route path="/clientes" element={<Clientes />} />
          <Route path="/casasDeApostas" element={<CasasDeApostas />} />
          <Route path="/grupos" element={<Grupos />} />
          <Route path="/sinais" element={<Sinais />} />
          <Route path="/leadsclientes" element={<LeadsCliente />} />
          <Route path="/replicador" element={<Replicador />} />
          <Route path="/criarbot" element={<CriarBot />} />
          <Route path="/criarwebapp" element={<CriarWebApp />} />
          <Route path="/links" element={<Links />} />
          <Route path="/perguntasBot" element={<PerguntasBot />} />
          <Route path="/monitor/:chatId" element={<MonitorPage />} />
          <Route path="/monitor" element={<MonitorGrupos />} />
          
        </Route>

        <Route path='/login'element={<Loginpage/>}></Route>
        <Route path="/RecuperarSenha" element={<RecuperarSenha />} />
        <Route path="/RedefinirSenha" element={<RedefinirSenha />} />
      </Routes>
    </BrowserRouter>

  )
}
