import red from '@mui/material/colors/red'
import { createTheme } from '@mui/material/styles'

export const theme = createTheme({
  typography: {
    fontFamily: 'Montserrat',
    body1: {
      color: '#FFF',
    },
  },
  palette: {
    background: {
      paper: '#17295540',
    },
    white: {
      main: '#FFF',
    },
    text: {
      primary: '#FFF',
    },
    primary: {
      main: '#0D2867',
      contrastText: '#FFFFFF',
      light: '#CCD2E5',
    },
    secondary: {
      main: '#E2C03C',
      light: '#EFE6C4',
      contrastText: '#FFFFFF',
    },
    tonalOffset: 0.2,
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          color: '#FFF',
          height: '52px',
          borderRadius: '4px',
        },
      },
    },
    MuiBadge: {
      styleOverrides: {
        root: {
          width: '40px',
          height: '40px',
          alignItems: 'center',
          justifyContent: 'center',
          background: '#2F2F39',
          borderRadius: '25px',
          boxShadow: '0px 5px 5px rgba(0, 0, 0, 0.35)',
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          fill: '#FFF',
          color: '#FFF',
        },
      },
    },
    MuiIcon: {
      styleOverrides: {
        root: {
          fill: '#FFF',
          color: '#FFF',
          zIndex: 999,
        }
      }
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: '#6D6D6D',
          '&.Mui-focused': {
            color: '#ffffff',
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "& fieldset": {
            borderColor: "#6D6D6D"
          },
          "&:hover fieldset": {
            borderColor: "#FFFFFF50"
          },
          "&.Mui-focused fieldset": {
            borderColor: "#FFF"
          },
          '&$focused $notchedOutline': {
            borderColor: 'green',
            borderWidth: 1,
          },
          '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
            {
              borderColor: '#FFF',
            },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: ''
        }
      }
    },

    MuiDrawer: {
      styleOverrides: {
        root: {
          '& .MuiDrawer-paper':
            {
              backgroundColor: '#151520',
            },
        }
      }

    },
    

    MuiTextField: {
      styleOverrides: {
        root: {
          '& label.Mui-focused': {
            color: 'white',
          },
          '& .MuiInput-underline:after': {
            borderBottomColor: 'yellow',
          },
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: '#6D6D6D',
            },
            '&:hover fieldset': {
              borderColor: '#FFFFFF80',
            },
            '&.Mui-focused fieldset': {
              borderColor: 'yellow',
            },
          },
        },
      },
    },
  },
})

declare module '@mui/material/styles' {
  interface Palette {
    white: Palette['primary']
  }

  // allow configuration using `createTheme`
  interface PaletteOptions {
    white?: PaletteOptions['primary']
  }
}

declare module '@mui/material/IconButton' {
  interface IconButtonPropsColorOverrides {
    white: true
  }
}


